const rewards = {
  "47": {
    //Ironforge
    Friendly: [
      {
        name: "Ironforge Tabard",
        id: "45577",
      },
    ],
    Revered: [
      {
        name: "Ironforge Satchel",
        id: "67528",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Ironforge",
        id: "64898",
      },
      {
        name: "Cape of Ironforge",
        id: "64899",
      },
      {
        name: "Mantle of Ironforge",
        id: "64900",
      },
    ],
  },
  "54": {
    //Gnomeregan
    Friendly: [
      {
        name: "Gnomeregan Tabard",
        id: "45578",
      },
    ],
    Revered: [
      {
        name: "Gnomeregan Satchel",
        id: "67528",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Gnomeregan",
        id: "64897",
      },
      {
        name: "Cape of Gnomeregan",
        id: "64896",
      },
      {
        name: "Mantle of Gnomeregan",
        id: "64895",
      },
    ],
  },
  "59": {
    //Thorium Brotherhood
    Friendly: [
      {
        name: "Scavenged Felsoul Sabatons",
        id: "20761",
      },
      {
        name: "Plans: Dark Iron Bracers",
        id: "17051",
      },
      {
        name: "Pattern: Molten Helm",
        id: "17023",
      },
      {
        name: "Pattern: Corehound Boots",
        id: "17022",
      },
      {
        name: "Pattern: Flarecore Gloves",
        id: "17018",
      },
      {
        name: "Formula: Enchant Weapon - Strength",
        id: "19444",
      },
    ],
    Honored: [
      {
        name: "Plans: Fiery Chain Girdle",
        id: "17049",
      },
      {
        name: "Plans: Dark Iron Helm",
        id: "19206",
      },
      {
        name: "Plans: Dark Iron Destroyer",
        id: "17060",
      },
      {
        name: "Plans: Dark Iron Reaver",
        id: "17059",
      },
      {
        name: "Formula: Enchant Weapon - Mighty Versatility",
        id: "19448",
      },
      {
        name: "Pattern: Lava Belt",
        id: "19330",
      },
      {
        name: "Pattern: Black Dragonscale Boots",
        id: "17025",
      },
      {
        name: "Pattern: Flarecore Mantle",
        id: "17017",
      },
      {
        name: "Pattern: Flarecore Robe",
        id: "19219",
      },
    ],
    Revered: [
      {
        name: "Plans: Fiery Chain Shoulders",
        id: "17053",
      },
      {
        name: "Plans: Dark Iron Leggings",
        id: "17052",
      },
      {
        name: "Plans: Dark Iron Gauntlets",
        id: "19207",
      },
      {
        name: "Plans: Black Amnesty",
        id: "19208",
      },
      {
        name: "Plans: Blackfury",
        id: "19209",
      },
      {
        name: "Formula: Enchant Weapon - Mighty Intellect",
        id: "19449",
      },
      {
        name: "Pattern: Chromatic Gauntlets",
        id: "19331",
      },
      {
        name: "Pattern: Molten Belt",
        id: "19333",
      },
      {
        name: "Pattern: Corehound Belt",
        id: "19332",
      },
      {
        name: "Pattern: Flarecore Leggings",
        id: "19220",
      },
    ],
    Exalted: [
      {
        name: "Plans: Dark Iron Boots",
        id: "20040",
      },
      {
        name: "Plans: Nightfall",
        id: "19212",
      },
      {
        name: "Plans: Ebon Hand",
        id: "19210",
      },
      {
        name: "Plans: Blackguard",
        id: "19211",
      },
    ],
  },
  "69": {
    //Darnassus
    Friendly: [
      {
        name: "Darnassus Tabard",
        id: "45579",
      },
    ],
    Revered: [
      {
        name: "Darnassus Satchel",
        id: "67526",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Darnassus",
        id: "64886",
      },
      {
        name: "Cape of Darnassus",
        id: "64887",
      },
      {
        name: "Mantle of Darnassus",
        id: "64888",
      },
    ],
  },
  "72": {
    //Stormwind
    Friendly: [
      {
        name: "Stormwind Tabard",
        id: "45574",
      },
    ],
    Revered: [
      {
        name: "Stormwind Satchel",
        id: "67531",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Stormwind",
        id: "64903",
      },
      {
        name: "Cape of Stormwind",
        id: "64902",
      },
      {
        name: "Mantle of Stormwind",
        id: "64901",
      },
    ],
  },
  "87": {
    //Bloodsail Buccaneers
    Friendly: [
      {
        name: "Bloodsail Admiral's Hat",
        id: "12185",
      },
      {
        name: "Bloodsail Shirt",
        id: "22742",
      },
      {
        name: "Bloodsail Sash",
        id: "22743",
      },
      {
        name: "Bloodsail Pants",
        id: "22745",
      },
    ],
  },
  "509": {
    //The League of Arathor
    Exalted: [
      {
        name: "Arathor Battle Tabard",
        id: "20132",
      },
    ],
  },
  "529": {
    //Argent Dawn
    Friendly: [
      {
        name: "Enriched Manna Biscuit",
        id: "13724",
      },
    ],
    Honored: [
      {
        name: "Formula: Powerful Anti-Venom",
        id: "19442",
      },
      {
        name: "Plans: Girdle of the Dawn",
        id: "19203",
      },
      {
        name: "Pattern: Argent Boots",
        id: "19216",
      },
      {
        name: "Formula: Enchant Bracer - Argent Versatility",
        id: "19446",
      },
      {
        name: "Pattern: Dawn Treaders",
        id: "19328",
      },
      {
        name: "Blessed Sunfruit Juice",
        id: "13813",
      },
      {
        name: "Recipe: Transmute Air to Fire",
        id: "13482",
      },
    ],
    Revered: [
      {
        name: "Formula: Enchant Bracer - Healing Power",
        id: "19447",
      },
      {
        name: "Pattern: Golden Mantle of the Dawn",
        id: "19329",
      },
      {
        name: "Plans: Gloves of the Dawn",
        id: "19205",
      },
      {
        name: "Pattern: Argent Shoulders",
        id: "19217",
      },
      {
        name: "Blessed Sunfruit",
        id: "13810",
      },
    ],
  },
  "579": {
    //Timbermaw Hold
    Friendly: [
      {
        name: "Formula: Enchant 2H Weapon - Agility",
        id: "22392",
      },
      {
        name: "Pattern: Warbear Woolies",
        id: "20254",
      },
      {
        name: "Pattern: Warbear Harness",
        id: "20253",
      },
      {
        name: "Recipe: Transmute Earth to Water",
        id: "13484",
      },
    ],
    Honored: [
      {
        name: "Formula: Enchant Weapon - Agility",
        id: "19445",
      },
      {
        name: "Pattern: Might of the Timbermaw",
        id: "19326",
      },
      {
        name: "Pattern: Wisdom of the Timbermaw",
        id: "19215",
      },
      {
        name: "Plans: Heavy Timbermaw Belt",
        id: "19202",
      },
    ],
    Revered: [
      {
        name: "Pattern: Timbermaw Brawlers",
        id: "19327",
      },
      {
        name: "Pattern: Mantle of the Timbermaw",
        id: "19218",
      },
      {
        name: "Plans: Heavy Timbermaw Boots",
        id: "19204",
      },
    ],
  },
  "609": {
    //Cenarion Circle
    Friendly: [
      {
        name: "Pattern: Bramblewood Belt",
        id: "22769",
      },
      {
        name: "Pattern: Cenarion Herb Bag",
        id: "22310",
      },
      {
        name: "Pattern: Sandstalker Bracers",
        id: "20509",
      },
      {
        name: "Pattern: Spitfire Bracers",
        id: "20506",
      },
      {
        name: "Pattern: Sylvan Shoulders",
        id: "22772",
      },
      {
        name: "Plans: Heavy Obsidian Belt",
        id: "22209",
      },
      {
        name: "Plans: Ironvine Belt",
        id: "22768",
      },
    ],
    Honored: [
      {
        name: "Pattern: Bramblewood Boots",
        id: "22770",
      },
      {
        name: "Pattern: Sandstalker Gauntlets",
        id: "20510",
      },
      {
        name: "Pattern: Spitfire Gauntlets",
        id: "20507",
      },
      {
        name: "Pattern: Sylvan Crown",
        id: "22773",
      },
      {
        name: "Plans: Ironvine Gloves",
        id: "22767",
      },
      {
        name: "Plans: Light Obsidian Belt",
        id: "22214",
      },
    ],
    Revered: [
      {
        name: "Pattern: Bramblewood Helm",
        id: "22771",
      },
      {
        name: "Pattern: Gaea's Embrace",
        id: "22683",
      },
      {
        name: "Pattern: Sandstalker Breastplate",
        id: "20511",
      },
      {
        name: "Pattern: Satchel of Cenarius",
        id: "22312",
      },
      {
        name: "Pattern: Spitfire Breastplate",
        id: "20508",
      },
      {
        name: "Pattern: Sylvan Vest",
        id: "22774",
      },
      {
        name: "Plans: Ironvine Breastplate",
        id: "22766",
      },
      {
        name: "Plans: Jagged Obsidian Shield",
        id: "22219",
      },
    ],
    Exalted: [
      {
        name: "Pattern: Dreamscale Breastplate",
        id: "20382",
      },
      {
        name: "Plans: Obsidian Mail Tunic",
        id: "22221",
      },
    ],
  },
  "930": {
    //Exodar
    Friendly: [
      {
        name: "Exodar Tabard",
        id: "45580",
      },
    ],
    Revered: [
      {
        name: "Exodar Satchel",
        id: "67527",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Exodar",
        id: "64891",
      },
      {
        name: "Cape of Exodar",
        id: "64890",
      },
      {
        name: "Mantle of Exodar",
        id: "64889",
      },
    ],
  },
  "932": {
    //The Aldor
    Friendly: [
      {
        name: "Design: Smooth Golden Draenite",
        id: "23149",
      },
      {
        name: "Pattern: Flameheart Bracers",
        id: "30842",
      },
      {
        name: "Plans: Flamebane Bracers",
        id: "23601",
      },
    ],
    Honored: [
      {
        name: "Anchorite's Robes",
        id: "29129",
      },
      {
        name: "Design: Purified Shadow Draenite",
        id: "23145",
      },
      {
        name: "Inscription of Discipline",
        id: "28881",
      },
      {
        name: "Inscription of Faith",
        id: "28878",
      },
      {
        name: "Inscription of Vengeance",
        id: "28885",
      },
      {
        name: "Inscription of Warding",
        id: "28882",
      },
      {
        name: "Pattern: Blastguard Belt",
        id: "29704",
      },
      {
        name: "Pattern: Flameheart Gloves",
        id: "30843",
      },
      {
        name: "Pattern: Flamescale Belt",
        id: "29693",
      },
      {
        name: "Pattern: Silver Spellthread",
        id: "24293",
      },
      {
        name: "Plans: Flamebane Gloves",
        id: "23603",
      },
    ],
    Revered: [
      {
        name: "Auchenai Staff",
        id: "29130",
      },
      {
        name: "Design: Pendant of Shadow's End",
        id: "24177",
      },
      {
        name: "Lightwarden's Band",
        id: "29128",
      },
      {
        name: "Pattern: Blastguard Boots",
        id: "29703",
      },
      {
        name: "Vindicator's Hauberk",
        id: "29127",
      },
      {
        name: "Pattern: Flamescale Boots",
        id: "29691",
      },
      {
        name: "Pattern: Vindicator's Armor Kit",
        id: "25721",
      },
      {
        name: "Plans: Flamebane Breastplate",
        id: "23604",
      },
    ],
    Exalted: [
      {
        name: "Medallion of the Lightbearer",
        id: "29123",
      },
      {
        name: "Vindicator's Brand",
        id: "29124",
      },
      {
        name: "Greater Inscription of Discipline",
        id: "28886",
      },
      {
        name: "Greater Inscription of Faith",
        id: "28887",
      },
      {
        name: "Greater Inscription of Vengeance",
        id: "28888",
      },
      {
        name: "Greater Inscription of Warding",
        id: "28889",
      },
      {
        name: "Aldor Tabard",
        id: "31779",
      },
      {
        name: "Pattern: Blastguard Pants",
        id: "29702",
      },
      {
        name: "Pattern: Flameheart Vest",
        id: "30844",
      },
      {
        name: "Pattern: Flamescale Leggings",
        id: "29689",
      },
      {
        name: "Pattern: Golden Spellthread",
        id: "24295",
      },
      {
        name: "Plans: Flamebane Helm",
        id: "23602",
      },
    ],
  },
  "933": {
    //The Consortium
    Friendly: [
      {
        name: "Pattern: Fel Leather Gloves",
        id: "25732",
      },
      {
        name: "Design: Reckless Flame Spessarite",
        id: "23136",
      },
      {
        name: "Design: Shifting Shadow Draenite",
        id: "23146",
      },
      {
        name: "Formula: Enchant Cloak - PvP Power",
        id: "28274",
      },
    ],
    Honored: [
      {
        name: "Gift of the Ethereal",
        id: "29456",
      },
      {
        name: "Nethershard",
        id: "29457",
      },
      {
        name: "Pattern: Fel Leather Boots",
        id: "25733",
      },
      {
        name: "Design: Delicate Blood Garnet",
        id: "23134",
      },
      {
        name: "Design: Sparkling Azure Moonstone",
        id: "23155",
      },
      {
        name: "Design: Subtle Golden Draenite",
        id: "23150",
      },
      {
        name: "Design: Powerful Earthstorm Diamond",
        id: "25902",
      },
      {
        name: "Design: Swift Skyfire Diamond",
        id: "25908",
      },
      {
        name: "Formula: Enchant Weapon - Major Striking",
        id: "22552",
      },
      {
        name: "Pattern: Bag of Jewels",
        id: "24314",
      },
    ],
    Revered: [
      {
        name: "Consortium Blaster",
        id: "29115",
      },
      {
        name: "Design: Pendant of the Null Rune",
        id: "24178",
      },
      {
        name: "Illusion: Executioner",
        id: "138796",
      },
      {
        name: "Nomad's Leggings",
        id: "29116",
      },
      {
        name: "Pattern: Fel Leather Leggings",
        id: "25734",
      },
      {
        name: "Stormspire Vest",
        id: "29117",
      },
      {
        name: "Design: Bracing Earthstorm Diamond",
        id: "25903",
      },
      {
        name: "Design: Crimson Sun",
        id: "33156",
      },
      {
        name: "Design: Don Julio's Heart",
        id: "33305",
      },
      {
        name: "Schematic: Elemental Seaforium Charge",
        id: "23874",
      },
    ],
    Exalted: [
      {
        name: "Guile of Khoraazi",
        id: "29121",
      },
      {
        name: "Haramad's Bargain",
        id: "29119",
      },
      {
        name: "Nether Runner's Cowl",
        id: "29122",
      },
      {
        name: "Design: Relentless Earthstorm Diamond",
        id: "33622",
      },
      {
        name: "Consortium Tabard",
        id: "31776",
      },
    ],
  },
  "934": {
    //The Scryers
    Friendly: [
      {
        name: "Design: Brilliant Blood Garnet",
        id: "23133",
      },
      {
        name: "Plans: Enchanted Adamantite Belt",
        id: "23597",
      },
    ],
    Honored: [
      {
        name: "Inscription of the Blade",
        id: "28907",
      },
      {
        name: "Inscription of the Knight",
        id: "28908",
      },
      {
        name: "Inscription of the Oracle",
        id: "28904",
      },
      {
        name: "Inscription of the Orb",
        id: "28903",
      },
      {
        name: "Pattern: Enchanted Clefthoof Boots",
        id: "29701",
      },
      {
        name: "Pattern: Enchanted Felscale Gloves",
        id: "29682",
      },
      {
        name: "Pattern: Mystic Spellthread",
        id: "24292",
      },
      {
        name: "Plans: Enchanted Adamantite Boots",
        id: "23598",
      },
    ],
    Revered: [
      {
        name: "Design: Pendant of Withering",
        id: "24176",
      },
      {
        name: "Gauntlets of the Chosen",
        id: "29134",
      },
      {
        name: "Retainer's Leggings",
        id: "29131",
      },
      {
        name: "Scryer's Bloodgem",
        id: "29132",
      },
      {
        name: "Seer's Cane",
        id: "29133",
      },
      {
        name: "Recipe: Elixir of Major Firepower",
        id: "22908",
      },
      {
        name: "Pattern: Enchanted Clefthoof Gloves",
        id: "29700",
      },
      {
        name: "Pattern: Enchanted Felscale Boots",
        id: "29684",
      },
      {
        name: "Pattern: Magister's Armor Kit",
        id: "25722",
      },
      {
        name: "Plans: Enchanted Adamantite Breastplate",
        id: "23599",
      },
    ],
    Exalted: [
      {
        name: "Retainer's Blade",
        id: "29125",
      },
      {
        name: "Greater Inscription of the Blade",
        id: "28910",
      },
      {
        name: "Greater Inscription of the Knight",
        id: "28911",
      },
      {
        name: "Greater Inscription of the Oracle",
        id: "28912",
      },
      {
        name: "Scryers Tabard",
        id: "31780",
      },
      {
        name: "Seer's Signet",
        id: "29126",
      },
      {
        name: "Greater Inscription of the Orb",
        id: "28909",
      },
      {
        name: "Pattern: Enchanted Clefthoof Leggings",
        id: "29698",
      },
      {
        name: "Pattern: Enchanted Felscale Leggings",
        id: "29677",
      },
      {
        name: "Pattern: Runic Spellthread",
        id: "24294",
      },
      {
        name: "Plans: Enchanted Adamantite Leggings",
        id: "23600",
      },
    ],
  },
  "935": {
    //The Sha'Tar
    Friendly: [
      {
        name: "Design: Insightful Earthstorm Diamond",
        id: "25904",
      },
    ],
    Honored: [
      {
        name: "Crusader's Ornamented Headguard",
        id: "35404",
      },
      {
        name: "Crusader's Scaled Shoulders",
        id: "35416",
      },
      {
        name: "Design: Ring of Arcane Shielding",
        id: "30826",
      },
      {
        name: "Dragonhide Spaulders",
        id: "35359",
      },
      {
        name: "Dreadweave Leggings",
        id: "35330",
      },
      {
        name: "Evoker's Silk Handguards",
        id: "35345",
      },
      {
        name: "Kodohide Helm",
        id: "35362",
      },
      {
        name: "Mooncloth Cowl",
        id: "35333",
      },
      {
        name: "Opportunist's Leather Legguards",
        id: "35368",
      },
      {
        name: "Satin Mantle",
        id: "35341",
      },
      {
        name: "Savage Plate Chestpiece",
        id: "35407",
      },
      {
        name: "Seer's Linked Armor",
        id: "35381",
      },
      {
        name: "Seer's Mail Helm",
        id: "35388",
      },
      {
        name: "Seer's Ringmail Shoulderpads",
        id: "35395",
      },
      {
        name: "Stalker's Chain Spaulders",
        id: "35380",
      },
      {
        name: "Wyrmhide Robe",
        id: "35375",
      },
      {
        name: "Formula: Enchant Gloves - Major Healing",
        id: "28273",
      },
      {
        name: "Design: Kailee's Rose",
        id: "33155",
      },
      {
        name: "Pattern: Drums of Battle",
        id: "29717",
      },
    ],
    Revered: [
      {
        name: "Blessed Scale Girdle",
        id: "29180",
      },
      {
        name: "Design: Talasite Owl",
        id: "24182",
      },
      {
        name: "Xi'ri's Gift",
        id: "29179",
      },
      {
        name: "Formula: Enchant Weapon - Major Healing",
        id: "28281",
      },
      {
        name: "Recipe: Transmute Primal Air to Fire",
        id: "22915",
      },
      {
        name: "Design: Blood of Amber",
        id: "33159",
      },
      {
        name: "Recipe: Alchemist Stone",
        id: "13517",
      },
    ],
    Exalted: [
      {
        name: "A'dal's Command",
        id: "29177",
      },
      {
        name: "Crest of the Sha'tar",
        id: "29176",
      },
      {
        name: "Gavel of Pure Light",
        id: "29175",
      },
      {
        name: "Formula: Enchant Gloves - Threat",
        id: "33153",
      },
      {
        name: "Recipe: Flask of the Titans",
        id: "31354",
      },
      {
        name: "Sha'tar Tabard",
        id: "31781",
      },
    ],
  },
  "942": {
    //Cenarion Expedition
    Friendly: [
      {
        name: "Pattern: Heavy Clefthoof Boots",
        id: "25737",
      },
      {
        name: "Expedition Flare",
        id: "24429",
      },
      {
        name: "Schematic: Green Smoke Flare",
        id: "23814",
      },
    ],
    Honored: [
      {
        name: "Crusader's Ornamented Gloves",
        id: "35403",
      },
      {
        name: "Crusader's Scaled Legguards",
        id: "35415",
      },
      {
        name: "Pattern: Heavy Clefthoof Leggings",
        id: "25736",
      },
      {
        name: "Dragonhide Legguards",
        id: "35358",
      },
      {
        name: "Dreadweave Hood",
        id: "35329",
      },
      {
        name: "Pattern: Heavy Clefthoof Vest",
        id: "25735",
      },
      {
        name: "Kodohide Shoulderpads",
        id: "35365",
      },
      {
        name: "Mooncloth Cowl",
        id: "35336",
      },
      {
        name: "Opportunist's Leather Helm",
        id: "35367",
      },
      {
        name: "Satin Robe",
        id: "35342",
      },
      {
        name: "Savage Plate Gauntlets",
        id: "35408",
      },
      {
        name: "Seer's Linked Spaulders",
        id: "35385",
      },
      {
        name: "Seer's Mail Gauntlets",
        id: "35387",
      },
      {
        name: "Seer's Ringmail Legguards",
        id: "35394",
      },
      {
        name: "Stalker's Chain Leggings",
        id: "35379",
      },
      {
        name: "Wyrmhide Spaulders",
        id: "35374",
      },
      {
        name: "Warden's Hauberk",
        id: "25838",
      },
      {
        name: "Pattern: Clefthide Leg Armor",
        id: "29720",
      },
      {
        name: "Plans: Adamantite Sharpening Stone",
        id: "23618",
      },
      {
        name: "Plans: Adamantite Weightstone",
        id: "28632",
      },
      {
        name: "Plans: Greater Rune of Warding",
        id: "25526",
      },
      {
        name: "Recipe: Earthen Elixir",
        id: "32070",
      },
      {
        name: "Recipe: Transmute Earthstorm Diamond",
        id: "25869",
      },
      {
        name: "Evoker's Silk Trousers",
        id: "35347",
      },
      {
        name: "Explorer's Walking Stick",
        id: "25835",
      },
      {
        name: "Preserver's Cudgel",
        id: "25836",
      },
    ],
    Revered: [
      {
        name: "Plans: Wildguard Helm",
        id: "31392",
      },
      {
        name: "Plans: Wildguard Leggings",
        id: "31391",
      },
      {
        name: "Design: Nightseye Panther",
        id: "24183",
      },
      {
        name: "Strength of the Untamed",
        id: "29173",
      },
      {
        name: "Watcher's Cowl",
        id: "29174",
      },
      {
        name: "Formula: Enchant Gloves - Precise Strikes",
        id: "28271",
      },
      {
        name: "Recipe: Transmute Primal Water to Air",
        id: "22918",
      },
    ],
    Exalted: [
      {
        name: "Ashyen's Gift",
        id: "29172",
      },
      {
        name: "Cenarion War Hippogryph",
        id: "33999",
      },
      {
        name: "Design: The Natural Ward",
        id: "31402",
      },
      {
        name: "Earthwarden",
        id: "29171",
      },
      {
        name: "Plans: Wildguard Breastplate",
        id: "31390",
      },
      {
        name: "Windcaller's Orb",
        id: "29170",
      },
      {
        name: "Formula: Enchant Cloak - Stealth",
        id: "33149",
      },
      {
        name: "Recipe: Flask of Distilled Wisdom",
        id: "31356",
      },
      {
        name: "Pattern: Nethercleft Leg Armor",
        id: "29721",
      },
      {
        name: "Recipe: Major Nature Protection Potion",
        id: "22922",
      },
      {
        name: "Cenarion Expedition Tabard",
        id: "31804",
      },
    ],
  },
  "946": {
    //Honor Hold
    Friendly: [
      {
        name: "Design: Regal Deep Peridot",
        id: "23142",
      },
      {
        name: "Dried Mushroom Rations",
        id: "24008",
      },
      {
        name: "Footman's Waterskin",
        id: "24007",
      },
      {
        name: "Formula: Enchant Bracer - Superior Healing",
        id: "22531",
      },
      {
        name: "Pattern: Felstalker Belt",
        id: "29213",
      },
    ],
    Honored: [
      {
        name: "Crusader's Ornamented Spaulders",
        id: "35476",
      },
      {
        name: "Crusader's Scaled Gauntlets",
        id: "35477",
      },
      {
        name: "Dragonhide Robe",
        id: "35469",
      },
      {
        name: "Dreadweave Robe",
        id: "35464",
      },
      {
        name: "Evoker's Silk Amice",
        id: "35465",
      },
      {
        name: "Kodohide Spaulders",
        id: "35470",
      },
      {
        name: "Mooncloth Vestments",
        id: "35467",
      },
      {
        name: "Opportunist's Leather Gloves",
        id: "35468",
      },
      {
        name: "Satin Hood",
        id: "35466",
      },
      {
        name: "Savage Plate Helm",
        id: "35478",
      },
      {
        name: "Seer's Linked Helm",
        id: "35474",
      },
      {
        name: "Seer's Mail Armor",
        id: "35472",
      },
      {
        name: "Seer's Ringmail Gloves",
        id: "35473",
      },
      {
        name: "Stalker's Chain Gauntlets",
        id: "35475",
      },
      {
        name: "Wyrmhide Gloves",
        id: "35471",
      },
      {
        name: "Footman's Longsword",
        id: "25825",
      },
      {
        name: "Pattern: Felstalker Bracers",
        id: "29214",
      },
      {
        name: "Pattern: Felstalker Breastplate",
        id: "29215",
      },
      {
        name: "Sage's Band",
        id: "25826",
      },
      {
        name: "Pattern: Cobrahide Leg Armor",
        id: "29719",
      },
      {
        name: "Recipe: Elixir of Major Agility",
        id: "22905",
      },
      {
        name: "Recipe: Transmute Skyfire Diamond",
        id: "25870",
      },
    ],
    Revered: [
      {
        name: "Design: Dawnstone Crab",
        id: "24180",
      },
      {
        name: "Hellforged Halberd",
        id: "29166",
      },
      {
        name: "Ring of Convalescence",
        id: "29169",
      },
      {
        name: "Formula: Enchant Chest - Exceptional Stats",
        id: "22547",
      },
    ],
    Exalted: [
      {
        name: "Blade of the Archmage",
        id: "29153",
      },
      {
        name: "Honor's Call",
        id: "29156",
      },
      {
        name: "Veteran's Musket",
        id: "29151",
      },
      {
        name: "Formula: Enchant Cloak - Subtlety",
        id: "33150",
      },
      {
        name: "Pattern: Nethercobra Leg Armor",
        id: "29722",
      },
      {
        name: "Plans: Felsteel Shield Spike",
        id: "23619",
      },
      {
        name: "Honor Hold Tabard",
        id: "23999",
      },
    ],
  },
  "967": {
    //The Violet Eye
    Honored: [
      {
        name: "Design: The Frozen Eye",
        id: "31401",
      },
      {
        name: "Plans: Iceguard Breastplate",
        id: "31393",
      },
      {
        name: "Plans: Iceguard Helm",
        id: "31395",
      },
      {
        name: "Inscription of Endurance",
        id: "29187",
      },
    ],
    Revered: [
      {
        name: "Pattern: Shadowprowler's Chestguard",
        id: "33205",
      },
      {
        name: "Plans: Iceguard Leggings",
        id: "31394",
      },
    ],
    Exalted: [
      {
        name: "Pattern: Cloak of Darkness",
        id: "33124",
      },
      {
        name: "Formula: Enchant Weapon - Greater Agility",
        id: "33165",
      },
    ],
  },
  "970": {
    //Sporeggar
    Friendly: [
      {
        name: "Tallstalk Mushroom",
        id: "25548",
      },
    ],
    Honored: [
      {
        name: "Muck-Covered Drape",
        id: "25827",
      },
      {
        name: "Petrified Lichen Guard",
        id: "25828",
      },
      {
        name: "Redcap Toadstool",
        id: "25550",
      },
    ],
    Revered: [
      {
        name: "Hardened Stone Shard",
        id: "29150",
      },
      {
        name: "Sporeling's Firestick",
        id: "29149",
      },
      {
        name: "Recipe: Transmute Primal Earth to Water",
        id: "22916",
      },
      {
        name: "Pattern: Mycah's Botanical Bag",
        id: "38229",
      },
    ],
    Exalted: [
      {
        name: "Tiny Sporebat",
        id: "34478",
      },
      {
        name: "Recipe: Shrouding Potion",
        id: "22906",
      },
      {
        name: "Sporeggar Tabard",
        id: "31775",
      },
    ],
  },
  "978": {
    //Kurenai
    Friendly: [
      {
        name: "Pattern: Netherfury Belt",
        id: "29217",
      },
    ],
    Honored: [
      {
        name: "Pattern: Netherfury Leggings",
        id: "29219",
      },
      {
        name: "Pattern: Drums of Restoration",
        id: "34175",
      },
      {
        name: "Pattern: Drums of Speed",
        id: "34173",
      },
      {
        name: "Pattern: Reinforced Mining Bag",
        id: "30444",
      },
    ],
    Revered: [
      {
        name: "Band of Elemental Spirits",
        id: "29146",
      },
      {
        name: "Blackened Leather Spaulders",
        id: "29148",
      },
      {
        name: "Kurenai Kilt",
        id: "29142",
      },
      {
        name: "Pattern: Netherfury Boots",
        id: "29218",
      },
      {
        name: "Recipe: Transmute Primal Fire to Earth",
        id: "30443",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Cobalt Riding Talbuk",
        id: "31830",
      },
      {
        name: "Reins of the Cobalt War Talbuk",
        id: "29227",
      },
      {
        name: "Reins of the Silver Riding Talbuk",
        id: "31832",
      },
      {
        name: "Reins of the Silver War Talbuk",
        id: "29229",
      },
      {
        name: "Reins of the Tan Riding Talbuk",
        id: "31834",
      },
      {
        name: "Reins of the Tan War Talbuk",
        id: "29230",
      },
      {
        name: "Reins of the White Riding Talbuk",
        id: "31836",
      },
      {
        name: "Reins of the White War Talbuk",
        id: "29231",
      },
      {
        name: "Arechron's Gift",
        id: "29138",
      },
      {
        name: "Cloak of the Ancient Spirits",
        id: "29140",
      },
      {
        name: "Far Seer's Helm",
        id: "29136",
      },
      {
        name: "Kurenai Tabard",
        id: "31774",
      },
    ],
  },
  "989": {
    //Keepers of Time
    Honored: [
      {
        name: "Crusader's Ornamented Chestplate",
        id: "35402",
      },
      {
        name: "Crusader's Scaled Helm",
        id: "35414",
      },
      {
        name: "Dragonhide Gloves",
        id: "35356",
      },
      {
        name: "Dreadweave Gloves",
        id: "35328",
      },
      {
        name: "Evoker's Silk Raiment",
        id: "35346",
      },
      {
        name: "Kodohide Legguards",
        id: "35363",
      },
      {
        name: "Mooncloth Legguards",
        id: "35334",
      },
      {
        name: "Opportunist's Leather Spaulders",
        id: "35369",
      },
      {
        name: "Satin Gloves",
        id: "35338",
      },
      {
        name: "Savage Plate Legguards",
        id: "35410",
      },
      {
        name: "Seer's Linked Leggings",
        id: "35384",
      },
      {
        name: "Seer's Mail Spaulders",
        id: "35390",
      },
      {
        name: "Seer's Ringmail Headpiece",
        id: "35393",
      },
      {
        name: "Stalker's Chain Armor",
        id: "35376",
      },
      {
        name: "Wyrmhide Helm",
        id: "35372",
      },
      {
        name: "Formula: Enchant Gloves - Major Spellpower",
        id: "28272",
      },
      {
        name: "Design: Enigmatic Skyfire Diamond",
        id: "25910",
      },
      {
        name: "Design: Facet of Eternity",
        id: "33160",
      },
      {
        name: "Formula: Enchant Ring - Minor Intellect",
        id: "22536",
      },
      {
        name: "Pattern: Drums of Panic",
        id: "29713",
      },
    ],
    Revered: [
      {
        name: "Continuum Blade",
        id: "29185",
      },
      {
        name: "Design: Living Ruby Serpent",
        id: "24181",
      },
      {
        name: "Design: Pendant of Frozen Flame",
        id: "24174",
      },
      {
        name: "Timewarden's Leggings",
        id: "29184",
      },
      {
        name: "Design: Stone of Blades",
        id: "33158",
      },
    ],
    Exalted: [
      {
        name: "Bindings of the Timewalker",
        id: "29183",
      },
      {
        name: "Riftmaker",
        id: "29182",
      },
      {
        name: "Timelapse Shard",
        id: "29181",
      },
      {
        name: "Formula: Enchant Gloves - Superior Agility",
        id: "33152",
      },
      {
        name: "Keepers of Time Tabard",
        id: "31777",
      },
      {
        name: "Recipe: Flask of Supreme Power",
        id: "31355",
      },
    ],
  },
  "990": {
    //The Scale of the Sands
    Friendly: [
      {
        name: "Design: Bold Crimson Spinel",
        id: "32274",
      },
      {
        name: "Design: Brilliant Crimson Spinel",
        id: "32282",
      },
      {
        name: "Design: Delicate Crimson Spinel",
        id: "32277",
      },
      {
        name: "Design: Smooth Lionseye",
        id: "32291",
      },
      {
        name: "Design: Solid Empyrean Sapphire",
        id: "32286",
      },
      {
        name: "Design: Sparkling Empyrean Sapphire",
        id: "32287",
      },
      {
        name: "Design: Subtle Lionseye",
        id: "32284",
      },
    ],
    Honored: [
      {
        name: "Design: Forceful Seaspray Emerald",
        id: "35765",
      },
      {
        name: "Design: Jagged Seaspray Emerald",
        id: "32312",
      },
      {
        name: "Design: Steady Seaspray Emerald",
        id: "35764",
      },
      {
        name: "Design: Radiant Seaspray Emerald",
        id: "32310",
      },
      {
        name: "Design: Quick Lionseye",
        id: "35763",
      },
      {
        name: "Design: Glinting Shadowsong Amethyst",
        id: "32306",
      },
      {
        name: "Design: Purified Shadowsong Amethyst",
        id: "32311",
      },
      {
        name: "Design: Timeless Shadowsong Amethyst",
        id: "32301",
      },
      {
        name: "Design: Potent Pyrestone",
        id: "32304",
      },
      {
        name: "Design: Reckless Pyrestone",
        id: "35762",
      },
    ],
    Revered: [
      {
        name: "Design: Deadly Pyrestone",
        id: "32308",
      },
      {
        name: "Design: Regal Seaspray Emerald",
        id: "32309",
      },
      {
        name: "Design: Rigid Empyrean Sapphire",
        id: "32292",
      },
    ],
  },
  "1012": {
    //Ashtongue Deathsworn
    Friendly: [
      {
        name: "Pattern: Boots of Shackled Souls",
        id: "32429",
      },
      {
        name: "Pattern: Bracers of Shackled Souls",
        id: "32430",
      },
      {
        name: "Pattern: Redeemed Soul Cinch",
        id: "32436",
      },
      {
        name: "Pattern: Redeemed Soul Legguards",
        id: "32435",
      },
      {
        name: "Pattern: Soulguard Bracers",
        id: "32438",
      },
      {
        name: "Pattern: Soulguard Girdle",
        id: "32440",
      },
      {
        name: "Plans: Shadesteel Bracers",
        id: "32442",
      },
      {
        name: "Plans: Shadesteel Girdle",
        id: "32444",
      },
    ],
    Honored: [
      {
        name: "Pattern: Greaves of Shackled Souls",
        id: "32431",
      },
      {
        name: "Pattern: Night's End",
        id: "32447",
      },
      {
        name: "Pattern: Redeemed Soul Moccasins",
        id: "32433",
      },
      {
        name: "Pattern: Redeemed Soul Wristguards",
        id: "32434",
      },
      {
        name: "Pattern: Soulguard Leggings",
        id: "32439",
      },
      {
        name: "Pattern: Soulguard Slippers",
        id: "32437",
      },
      {
        name: "Pattern: Waistguard of Shackled Souls",
        id: "32432",
      },
      {
        name: "Plans: Shadesteel Greaves",
        id: "32443",
      },
      {
        name: "Plans: Shadesteel Sabots",
        id: "32441",
      },
    ],
    Exalted: [
      {
        name: "Ashtongue Talisman of Acumen",
        id: "32490",
      },
      {
        name: "Ashtongue Talisman of Equilibrium",
        id: "32486",
      },
      {
        name: "Ashtongue Talisman of Insight",
        id: "32488",
      },
      {
        name: "Ashtongue Talisman of Lethality",
        id: "32492",
      },
      {
        name: "Ashtongue Talisman of Shadows",
        id: "32493",
      },
      {
        name: "Ashtongue Talisman of Swiftness",
        id: "32487",
      },
      {
        name: "Ashtongue Talisman of Valor",
        id: "32485",
      },
      {
        name: "Ashtongue Talisman of Vision",
        id: "32491",
      },
      {
        name: "Ashtongue Talisman of Zeal",
        id: "32489",
      },
    ],
  },
  "1015": {
    //Netherwing
    Exalted: [
      {
        name: "Reins of the Onyx Netherwing Drake",
        id: "32857",
      },
      {
        name: "Reins of the Azure Netherwing Drake",
        id: "32858",
      },
      {
        name: "Reins of the Cobalt Netherwing Drake",
        id: "32859",
      },
      {
        name: "Reins of the Purple Netherwing Drake",
        id: "32860",
      },
      {
        name: "Reins of the Veridian Netherwing Drake",
        id: "32861",
      },
      {
        name: "Reins of the Violet Netherwing Drake",
        id: "32862",
      },
    ],
  },
  "1031": {
    //Sha'tari Skyguard
    Friendly: [
      {
        name: "Enriched Terocone Juice",
        id: "32722",
      },
    ],
    Honored: [
      {
        name: "Skyguard Rations",
        id: "32721",
      },
    ],
    Revered: [
      {
        name: "Skyguard's Drape",
        id: "32539",
      },
      {
        name: "Skywitch's Drape",
        id: "32538",
      },
    ],
    Exalted: [
      {
        name: "Airman's Ribbon of Gallantry",
        id: "32771",
      },
      {
        name: "Blue Riding Nether Ray",
        id: "32319",
      },
      {
        name: "Green Riding Nether Ray",
        id: "32314",
      },
      {
        name: "Purple Riding Nether Ray",
        id: "32316",
      },
      {
        name: "Red Riding Nether Ray",
        id: "32317",
      },
      {
        name: "Silver Riding Nether Ray",
        id: "32318",
      },
      {
        name: "Skyguard Silver Cross",
        id: "32770",
      },
      {
        name: "Nether Ray Fry",
        id: "38628",
      },
      {
        name: "Skyguard Tabard",
        id: "32445",
      },
    ],
  },
  "1037": {
    //Alliance Vanguard
    Revered: [
      {
        name: "Gnomish Magician's Quill",
        id: "38464",
      },
      {
        name: "Hammer of the Alliance Vanguard",
        id: "38455",
      },
      {
        name: "Lordaeron's Resolve",
        id: "38463",
      },
      {
        name: "Orb of the Eastern Kingdoms",
        id: "38459",
      },
      {
        name: "Sawed-Off Hand Cannon",
        id: "38457",
      },
      {
        name: "Shield of the Lion-Hearted",
        id: "38453",
      },
      {
        name: "Vanguard Soldier's Dagger",
        id: "38465",
      },
    ],
    Exalted: [
      {
        name: "Schematic: Mekgineer's Chopper",
        id: "44503",
      },
      {
        name: "Plans: Titanium Plating",
        id: "44937",
      },
    ],
  },
  "1038": {
    //Ogri'la
    Friendly: [
      {
        name: "Blue Ogre Brew Special",
        id: "32909",
      },
      {
        name: "Red Ogre Brew Special",
        id: "32910",
      },
    ],
    Honored: [
      {
        name: "Blue Ogre Brew",
        id: "32783",
      },
      {
        name: "Red Ogre Brew",
        id: "32784",
      },
    ],
    Revered: [
      {
        name: "Apexis Cloak",
        id: "32653",
      },
      {
        name: "Cerulean Crystal Rod",
        id: "32650",
      },
      {
        name: "Crystalforged Trinket",
        id: "32654",
      },
      {
        name: "Ogri'la Aegis",
        id: "32652",
      },
    ],
    Exalted: [
      {
        name: "Crystal Orb of Enlightenment",
        id: "32651",
      },
      {
        name: "Crystalline Crossbow",
        id: "32645",
      },
      {
        name: "Shard-Bound Bracers",
        id: "32647",
      },
      {
        name: "Vortex Walking Boots",
        id: "32648",
      },
      {
        name: "Ogri'la Tabard",
        id: "32828",
      },
    ],
  },
  "1073": {
    //The Kalu'ak
    Friendly: [
      {
        name: "Design: Purified Shadow Crystal",
        id: "41568",
      },
      {
        name: "Freshly-Speared Emperor Salmon",
        id: "44049",
      },
    ],
    Honored: [
      {
        name: "Cuttlefish Scale Breastplate",
        id: "44059",
      },
      {
        name: "Cuttlefish Tooth Ringmail",
        id: "44060",
      },
      {
        name: "Ivory-Reinforced Chestguard",
        id: "44057",
      },
      {
        name: "Pigment-Stained Robes",
        id: "44061",
      },
      {
        name: "Turtle-Minders Robe",
        id: "44062",
      },
      {
        name: "Whale-Skin Breastplate",
        id: "44054",
      },
      {
        name: "Whalebone Carapace",
        id: "44058",
      },
      {
        name: "Design: Defender's Shadow Crystal",
        id: "41574",
      },
      {
        name: "Whale-Skin Vest",
        id: "44055",
      },
    ],
    Revered: [
      {
        name: "Pattern: Trapper's Traveling Pack",
        id: "44509",
      },
      {
        name: "Totemic Purification Rod",
        id: "44052",
      },
      {
        name: "Traditional Flensing Knife",
        id: "44051",
      },
      {
        name: "Whale-Stick Harpoon",
        id: "44053",
      },
      {
        name: "Pattern: Emerald Bag",
        id: "45774",
      },
    ],
    Exalted: [
      {
        name: "Mastercraft Kalu'ak Fishing Pole",
        id: "44050",
      },
      {
        name: "Nurtured Penguin Egg",
        id: "44723",
      },
    ],
  },
  "1077": {
    //Shattered Sun Offensive
    Friendly: [
      {
        name: "Design: Bold Crimson Spinel",
        id: "35244",
      },
      {
        name: "Design: Brilliant Crimson Spinel",
        id: "35248",
      },
      {
        name: "Design: Delicate Crimson Spinel",
        id: "35246",
      },
      {
        name: "Design: Smooth Lionseye",
        id: "35260",
      },
      {
        name: "Design: Solid Empyrean Sapphire",
        id: "35263",
      },
      {
        name: "Design: Sparkling Empyrean Sapphire",
        id: "35264",
      },
      {
        name: "Design: Subtle Lionseye",
        id: "35249",
      },
      {
        name: "Naaru Ration",
        id: "34780",
      },
    ],
    Honored: [
      {
        name: "Design: Glinting Shadowsong Amethyst",
        id: "35266",
      },
      {
        name: "Design: Jagged Seaspray Emerald",
        id: "35253",
      },
      {
        name: "Design: Potent Pyrestone",
        id: "35269",
      },
      {
        name: "Design: Purified Shadowsong Amethyst",
        id: "35251",
      },
      {
        name: "Design: Radiant Seaspray Emerald",
        id: "35254",
      },
      {
        name: "Design: Timeless Shadowsong Amethyst",
        id: "35239",
      },
      {
        name: "Formula: Enchant Chest - Dodge",
        id: "35500",
      },
      {
        name: "Formula: Void Shatter",
        id: "34872",
      },
    ],
    Revered: [
      {
        name: "Design: Forceful Seaspray Emerald",
        id: "35769",
      },
      {
        name: "Design: Quick Lionseye",
        id: "35768",
      },
      {
        name: "Design: Reckless Pyrestone",
        id: "35767",
      },
      {
        name: "Design: Steady Seaspray Emerald",
        id: "35766",
      },
      {
        name: "Archmage's Guile",
        id: "34667",
      },
      {
        name: "Bombardier's Blade",
        id: "34665",
      },
      {
        name: "Inuuro's Blade",
        id: "34672",
      },
      {
        name: "K'iru's Presage",
        id: "34671",
      },
      {
        name: "Legionfoe",
        id: "34673",
      },
      {
        name: "Seeker's Gavel",
        id: "34670",
      },
      {
        name: "The Sunbreaker",
        id: "34666",
      },
      {
        name: "Truestrike Crossbow",
        id: "34674",
      },
      {
        name: "Design: Deadly Pyrestone",
        id: "35271",
      },
      {
        name: "Design: Ember Skyfire Diamond",
        id: "35505",
      },
      {
        name: "Design: Eternal Earthstorm Diamond",
        id: "35502",
      },
      {
        name: "Design: Figurine - Crimson Serpent",
        id: "35697",
      },
      {
        name: "Design: Figurine - Empyrean Tortoise",
        id: "35695",
      },
      {
        name: "Design: Figurine - Khorium Boar",
        id: "35696",
      },
      {
        name: "Design: Figurine - Seaspray Albatross",
        id: "35699",
      },
      {
        name: "Design: Figurine - Shadowsong Panther",
        id: "35698",
      },
      {
        name: "Design: Regal Seaspray Emerald",
        id: "35252",
      },
      {
        name: "Design: Regal Talasite",
        id: "35708",
      },
      {
        name: "Design: Rigid Empyrean Sapphire",
        id: "35259",
      },
      {
        name: "Arcanum of the Gladiator",
        id: "29193",
      },
    ],
    Exalted: [
      {
        name: "Dawnforged Defender",
        id: "34676",
      },
      {
        name: "Shattered Sun Pendant of Acumen",
        id: "34678",
      },
      {
        name: "Shattered Sun Pendant of Might",
        id: "34679",
      },
      {
        name: "Shattered Sun Pendant of Resolve",
        id: "34680",
      },
      {
        name: "Shattered Sun Pendant of Restoration",
        id: "34677",
      },
      {
        name: "Sunward Crest",
        id: "34675",
      },
      {
        name: "Design: Forceful Talasite",
        id: "35325",
      },
      {
        name: "Design: Quick Dawnstone",
        id: "35322",
      },
      {
        name: "Design: Reckless Noble Topaz",
        id: "35323",
      },
      {
        name: "Tabard of the Shattered Sun",
        id: "35221",
      },
      {
        name: "Design: Flashing Crimson Spinel",
        id: "35247",
      },
      {
        name: "Design: Inscribed Pyrestone",
        id: "35267",
      },
      {
        name: "Design: Mystic Lionseye",
        id: "35258",
      },
      {
        name: "Design: Shifting Shadowsong Amethyst",
        id: "35242",
      },
      {
        name: "Design: Sovereign Shadowsong Amethyst",
        id: "35243",
      },
      {
        name: "Design: Stormy Empyrean Sapphire",
        id: "35265",
      },
      {
        name: "Design: Veiled Shadowsong Amethyst",
        id: "35270",
      },
      {
        name: "Recipe: Assassin's Alchemist Stone",
        id: "35755",
      },
      {
        name: "Recipe: Guardian's Alchemist Stone",
        id: "35752",
      },
      {
        name: "Recipe: Redeemer's Alchemist Stone",
        id: "35754",
      },
      {
        name: "Recipe: Sorcerer's Alchemist Stone",
        id: "35753",
      },
    ],
  },
  "1090": {
    //Kirin Tor
    Friendly: [
      {
        name: "Tabard of the Kirin Tor",
        id: "43157",
      },
    ],
    Honored: [
      {
        name: "Helm of the Majestic Stag",
        id: "44170",
      },
      {
        name: "Lightblade Rivener",
        id: "44166",
      },
      {
        name: "Shroud of Dedicated Research",
        id: "44167",
      },
      {
        name: "Spaulders of Grounded Lightning",
        id: "44171",
      },
    ],
    Revered: [
      {
        name: "Flameheart Spell Scalpel",
        id: "44173",
      },
      {
        name: "Girdle of the Warrior Magi",
        id: "44176",
      },
      {
        name: "Mind-Expanding Leggings",
        id: "44179",
      },
      {
        name: "Stave of Shrouded Mysteries",
        id: "44174",
      },
    ],
    Exalted: [
      {
        name: "Boots of Twinkling Stars",
        id: "44182",
      },
      {
        name: "Fireproven Gauntlets",
        id: "44183",
      },
      {
        name: "Ghostflicker Waistband",
        id: "44181",
      },
      {
        name: "Robes of Crackling Flame",
        id: "44180",
      },
      {
        name: "Design: Brilliant Scarlet Ruby",
        id: "41718",
      },
      {
        name: "Pattern: Sapphire Spellthread",
        id: "42188",
      },
    ],
  },
  "1091": {
    //The Wyrmrest Accord
    Friendly: [
      {
        name: "Tabard of the Wyrmrest Accord",
        id: "43156",
      },
    ],
    Honored: [
      {
        name: "Bracers of Accorded Courtesy",
        id: "44197",
      },
      {
        name: "Cloak of Peaceful Resolutions",
        id: "44188",
      },
      {
        name: "Fang of Truth",
        id: "44187",
      },
      {
        name: "Sash of the Wizened Wyrm",
        id: "44196",
      },
    ],
    Revered: [
      {
        name: "Ancestral Sinew Wristguards",
        id: "44200",
      },
      {
        name: "Breastplate of the Solemn Council",
        id: "44198",
      },
      {
        name: "Gavel of the Brewing Storm",
        id: "44199",
      },
      {
        name: "Sabatons of Draconic Vigor",
        id: "44201",
      },
      {
        name: "Pattern: Mysterious Bag",
        id: "42185",
      },
    ],
    Exalted: [
      {
        name: "Dragonfriend Bracers",
        id: "44203",
      },
      {
        name: "Grips of Fierce Pronouncements",
        id: "44204",
      },
      {
        name: "Legplates of Bloody Reprisal",
        id: "44205",
      },
      {
        name: "Reins of the Red Drake",
        id: "43955",
      },
      {
        name: "Sandals of Crimson Fury",
        id: "44202",
      },
      {
        name: "Design: Stalwart Monarch Topaz",
        id: "41722",
      },
    ],
  },
  "1094": {
    //The Silver Covenant
    Exalted: [
      {
        name: "Quel'dorei Steed",
        id: "46815",
      },
      {
        name: "Silver Covenant Hippogryph",
        id: "46813",
      },
      {
        name: "Shimmering Wyrmling",
        id: "46820",
      },
      {
        name: "Silver Covenant Tabard",
        id: "46817",
      },
    ],
  },
  "1104": {
    //Frenzyheart Tribe
    Friendly: [
      {
        name: "Design: Reckless Huge Citrine",
        id: "41561",
      },
      {
        name: "Nepeta Leaf",
        id: "44064",
      },
      {
        name: "Roasted Mystery Beast",
        id: "44072",
      },
    ],
    Revered: [
      {
        name: "Azure Strappy Pants",
        id: "44117",
      },
      {
        name: "Design: Jagged Forest Emerald",
        id: "41723",
      },
      {
        name: "Discarded Titanium Legplates",
        id: "44123",
      },
      {
        name: "Giant-Sized Gauntlets",
        id: "44120",
      },
      {
        name: "Muddied Crimson Gloves",
        id: "44116",
      },
      {
        name: "Scavenged Feathery Leggings",
        id: "44122",
      },
      {
        name: "Sparkly Shiny Gloves",
        id: "44121",
      },
      {
        name: "Disgusting Jar",
        id: "44717",
      },
    ],
    Exalted: [
      {
        name: "Frenzyheart Insignia of Fury",
        id: "44073",
      },
    ],
  },
  "1105": {
    //The Oracles
    Friendly: [
      {
        name: "Design: Nimble Dark Jade",
        id: "41567",
      },
      {
        name: "Oracle Secret Solution",
        id: "44065",
      },
    ],
    Honored: [
      {
        name: "Slow-Roasted Eel",
        id: "44071",
      },
    ],
    Revered: [
      {
        name: "Design: Misty Forest Emerald",
        id: "41724",
      },
      {
        name: "Fishy Cinch",
        id: "44104",
      },
      {
        name: "Glimmershell Shoulder Protectors",
        id: "44112",
      },
      {
        name: "Glitterscale Wrap",
        id: "44106",
      },
      {
        name: "Gold Star Spaulders",
        id: "44111",
      },
      {
        name: "Sharkjaw Cap",
        id: "44110",
      },
      {
        name: "Shinygem Rod",
        id: "44108",
      },
      {
        name: "Toothslice Helm",
        id: "44109",
      },
      {
        name: "Mysterious Egg",
        id: "39878",
      },
    ],
    Exalted: [
      {
        name: "Oracle Talisman of Ablution",
        id: "44074",
      },
    ],
  },
  "1119": {
    //The Sons of Hodir
    Honored: [
      {
        name: "Giant Ring Belt",
        id: "44189",
      },
      {
        name: "Lesser Inscription of the Axe",
        id: "44131",
      },
      {
        name: "Lesser Inscription of the Crag",
        id: "44130",
      },
      {
        name: "Lesser Inscription of the Pinnacle",
        id: "44132",
      },
      {
        name: "Lesser Inscription of the Storm",
        id: "44129",
      },
      {
        name: "Pattern: Mammoth Mining Bag",
        id: "44510",
      },
      {
        name: "Spaulders of Frozen Knives",
        id: "44190",
      },
    ],
    Revered: [
      {
        name: "Reins of the Ice Mammoth",
        id: "43958",
      },
      {
        name: "Reins of the Ice Mammoth",
        id: "44080",
      },
      {
        name: "Broken Stalactite",
        id: "44193",
      },
      {
        name: "Giant-Friend Kilt",
        id: "44194",
      },
      {
        name: "Spaulders of the Giant Lords",
        id: "44195",
      },
      {
        name: "Stalactite Chopper",
        id: "44192",
      },
    ],
    Exalted: [
      {
        name: "Greater Inscription of the Axe",
        id: "50335",
      },
      {
        name: "Greater Inscription of the Crag",
        id: "50336",
      },
      {
        name: "Greater Inscription of the Pinnacle",
        id: "50337",
      },
      {
        name: "Greater Inscription of the Storm",
        id: "50338",
      },
      {
        name: "Reins of the Grand Ice Mammoth",
        id: "43961",
      },
      {
        name: "Reins of the Grand Ice Mammoth",
        id: "44086",
      },
      {
        name: "Design: Smooth Autumn's Glow",
        id: "41720",
      },
      {
        name: "Pattern: Glacial Bag",
        id: "42184",
      },
    ],
  },
  "1134": {
    //Gilneas
    Friendly: [
      {
        name: "Gilneas Tabard",
        id: "64882",
      },
    ],
    Revered: [
      {
        name: "Gilnean Satchel",
        id: "67532",
      },
    ],
    Exalted: [
      {
        name: "Shroud of Gilneas",
        id: "64894",
      },
      {
        name: "Cape of Gilneas",
        id: "64893",
      },
      {
        name: "Mantle of Gilneas",
        id: "64892",
      },
    ],
  },
  "1156": {
    //The Ashen Verdict
    Friendly: [
      {
        name: "Ashen Band of Courage",
        id: "50375",
      },
      {
        name: "Ashen Band of Destruction",
        id: "50377",
      },
      {
        name: "Ashen Band of Might",
        id: "52569",
      },
      {
        name: "Ashen Band of Vengeance",
        id: "50376",
      },
      {
        name: "Ashen Band of Wisdom",
        id: "50378",
      },
    ],
    Honored: [
      {
        name: "Ashen Band of Greater Courage",
        id: "50388",
      },
      {
        name: "Ashen Band of Greater Destruction",
        id: "50384",
      },
      {
        name: "Ashen Band of Greater Might",
        id: "52570",
      },
      {
        name: "Ashen Band of Greater Vengeance",
        id: "50387",
      },
      {
        name: "Ashen Band of Greater Wisdom",
        id: "50386",
      },
      {
        name: "Pattern: Blessed Cenarion Boots",
        id: "49958",
      },
      {
        name: "Pattern: Deathfrost Boots",
        id: "49954",
      },
      {
        name: "Pattern: Earthsoul Boots",
        id: "49963",
      },
      {
        name: "Pattern: Footpads of Impending Death",
        id: "49961",
      },
      {
        name: "Pattern: Rock-Steady Treads",
        id: "49966",
      },
      {
        name: "Pattern: Sandals of Consecration",
        id: "49956",
      },
      {
        name: "Plans: Boots of Kingly Upheaval",
        id: "49974",
      },
      {
        name: "Plans: Hellfrozen Bonegrinders",
        id: "49972",
      },
      {
        name: "Plans: Protectors of Life",
        id: "49970",
      },
    ],
    Revered: [
      {
        name: "Ashen Band of Unmatched Courage",
        id: "50403",
      },
      {
        name: "Ashen Band of Unmatched Destruction",
        id: "50397",
      },
      {
        name: "Ashen Band of Unmatched Might",
        id: "52571",
      },
      {
        name: "Ashen Band of Unmatched Vengeance",
        id: "50401",
      },
      {
        name: "Ashen Band of Unmatched Wisdom",
        id: "50399",
      },
      {
        name: "Pattern: Bladeborn Leggings",
        id: "49959",
      },
      {
        name: "Pattern: Draconic Bonesplinter Legguards",
        id: "49965",
      },
      {
        name: "Pattern: Leggings of Woven Death",
        id: "49953",
      },
      {
        name: "Pattern: Legwraps of Unleashed Nature",
        id: "49957",
      },
      {
        name: "Pattern: Lightning-Infused Leggings",
        id: "49962",
      },
      {
        name: "Pattern: Lightweave Leggings",
        id: "49955",
      },
      {
        name: "Plans: Legplates of Painful Death",
        id: "49971",
      },
      {
        name: "Plans: Pillars of Might",
        id: "49973",
      },
      {
        name: "Plans: Puresteel Legplates",
        id: "49969",
      },
    ],
    Exalted: [
      {
        name: "Ashen Band of Endless Courage",
        id: "50404",
      },
      {
        name: "Ashen Band of Endless Destruction",
        id: "50398",
      },
      {
        name: "Ashen Band of Endless Might",
        id: "52572",
      },
      {
        name: "Ashen Band of Endless Vengeance",
        id: "50402",
      },
      {
        name: "Ashen Band of Endless Wisdom",
        id: "50400",
      },
    ],
  },
  "1177": {
    //Baradin's Wardens
    Friendly: [
      {
        name: "Baradin's Wardens Commendation",
        id: "63517",
      },
      {
        name: "Baradin's Wardens Bandage",
        id: "63391",
      },
      {
        name: "Baradin's Wardens Healing Potion",
        id: "63144",
      },
      {
        name: "Baradin's Wardens Mana Potion",
        id: "63145",
      },
    ],
    Honored: [
      {
        name: "Baradin Footman's Tags",
        id: "65175",
      },
      {
        name: "Baradin's Wardens Tabard",
        id: "63379",
      },
      {
        name: "Rustberg Gull",
        id: "63355",
      },
      {
        name: "Tol Barad Searchlight",
        id: "63141",
      },
    ],
    Revered: [
      {
        name: "Baradin's Wardens Battle Standard",
        id: "63377",
      },
      {
        name: "Blade of the Fearless",
        id: "62473",
      },
      {
        name: "Dagger of Restless Nights",
        id: "62475",
      },
      {
        name: "Darkheart Hacker",
        id: "68739",
      },
      {
        name: "Insidious Staff",
        id: "62477",
      },
      {
        name: "Ravening Slicer",
        id: "62476",
      },
      {
        name: "Shimmering Morningstar",
        id: "62478",
      },
      {
        name: "Sky Piercer",
        id: "62479",
      },
      {
        name: "Spear of Trailing Shadows",
        id: "62474",
      },
    ],
    Exalted: [
      {
        name: "Impatience of Youth",
        id: "62469",
      },
      {
        name: "Mandala of Stirring Patterns",
        id: "62472",
      },
      {
        name: "Mirror of Broken Images",
        id: "62471",
      },
      {
        name: "Reins of the Drake of the West Wind",
        id: "63039",
      },
      {
        name: "Reins of the Spectral Steed",
        id: "64998",
      },
      {
        name: "Stump of Time",
        id: "62470",
      },
      {
        name: "Unsolvable Riddle",
        id: "62468",
      },
      {
        name: "Fox Kit",
        id: "90897",
      },
    ],
  },
  "1204": {
    //Avengers of Hyjal
    Friendly: [
      {
        name: "Bladed Flamewrath Cover",
        id: "71227",
      },
      {
        name: "Durable Flamewrath Greatcloak",
        id: "70930",
      },
      {
        name: "Flowing Flamewrath Cape",
        id: "71229",
      },
      {
        name: "Rippling Flamewrath Drape",
        id: "71230",
      },
      {
        name: "Sleek Flamewrath Cloak",
        id: "71228",
      },
    ],
    Honored: [
      {
        name: "Belt of the Seven Seals",
        id: "71253",
      },
      {
        name: "Cinch of the Flaming Ember",
        id: "71250",
      },
      {
        name: "Embereye Belt",
        id: "71258",
      },
      {
        name: "Firearrow Belt",
        id: "71255",
      },
      {
        name: "Firemend Cinch",
        id: "71254",
      },
      {
        name: "Firescar Sash",
        id: "71249",
      },
      {
        name: "Flamebinding Girdle",
        id: "71131",
      },
      {
        name: "Girdle of the Indomitable Flame",
        id: "70933",
      },
    ],
    Revered: [
      {
        name: "Ancient Petrified Seed",
        id: "69001",
      },
      {
        name: "Essence of the Eternal Flame",
        id: "69002",
      },
      {
        name: "Fiery Quintessence",
        id: "69000",
      },
      {
        name: "Rune of Zeth",
        id: "68998",
      },
      {
        name: "Stay of Execution",
        id: "68996",
      },
    ],
    Exalted: [
      {
        name: "Adamantine Signet of the Avengers",
        id: "70934",
      },
      {
        name: "Infernal Signet of the Avengers",
        id: "71217",
      },
      {
        name: "Obsidian Signet of the Avengers",
        id: "71215",
      },
      {
        name: "Quicksilver Signet of the Avengers",
        id: "71237",
      },
      {
        name: "Viridian Signet of the Avengers",
        id: "71216",
      },
    ],
  },
  "1269": {
    //Golden Lotus
    Honored: [
      {
        name: "Pattern: Angerhide Leg Armor",
        id: "86235",
      },
      {
        name: "Pattern: Chestguard of Earthen Harmony",
        id: "86237",
      },
      {
        name: "Pattern: Gloves of Creation",
        id: "86371",
      },
      {
        name: "Pattern: Gloves of Earthen Harmony",
        id: "86273",
      },
      {
        name: "Pattern: Greater Cerulean Spellthread",
        id: "86376",
      },
      {
        name: "Pattern: Greater Pearlescent Spellthread",
        id: "86375",
      },
      {
        name: "Pattern: Greyshadow Chestguard",
        id: "86274",
      },
      {
        name: "Pattern: Greyshadow Gloves",
        id: "86275",
      },
      {
        name: "Pattern: Ironscale Leg Armor",
        id: "86276",
      },
      {
        name: "Pattern: Lifekeeper's Gloves",
        id: "86277",
      },
      {
        name: "Pattern: Lifekeeper's Robe",
        id: "86278",
      },
      {
        name: "Pattern: Robes of Creation",
        id: "86370",
      },
      {
        name: "Pattern: Shadowleather Leg Armor",
        id: "86295",
      },
      {
        name: "Pattern: Spelltwister's Gloves",
        id: "86369",
      },
      {
        name: "Pattern: Spelltwister's Grand Robe",
        id: "86368",
      },
      {
        name: "Pattern: Wildblood Gloves",
        id: "86308",
      },
      {
        name: "Pattern: Wildblood Vest",
        id: "86309",
      },
    ],
    Revered: [
      {
        name: "Grand Commendation of the Golden Lotus",
        id: "93215",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Azure Riding Crane",
        id: "87781",
      },
      {
        name: "Reins of the Golden Riding Crane",
        id: "87782",
      },
      {
        name: "Reins of the Regal Riding Crane",
        id: "87783",
      },
      {
        name: "Golden Lotus Tabard",
        id: "89797",
      },
    ],
  },
  "1272": {
    //The Tillers
    Revered: [
      {
        name: "Grand Commendation of the Tillers",
        id: "93226",
      },
      {
        name: "Pandaren Scarecrow",
        id: "89869",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Black Riding Goat",
        id: "89391",
      },
      {
        name: "Reins of the Brown Riding Goat",
        id: "89362",
      },
      {
        name: "Reins of the White Riding Goat",
        id: "89390",
      },
      {
        name: "Tillers Tabard",
        id: "89784",
      },
      {
        name: "Gin-Ji Knife Set",
        id: "90175",
      },
      {
        name: "Mourning Glory",
        id: "80914",
      },
      {
        name: "Recipe: Spicy Salmon",
        id: "74657",
      },
      {
        name: "Recipe: Spicy Vegetable Chips",
        id: "74658",
      },
    ],
  },
  "1302": {
    //The Anglers
    Friendly: [
      {
        name: "Recipe: Krasarang Fritters",
        id: "85505",
      },
      {
        name: "Recipe: Viseclaw Soup",
        id: "85502",
      },
    ],
    Honored: [
      {
        name: "Tiny Goldfish",
        id: "85447",
      },
      {
        name: "Pandaren Fishing Pole",
        id: "84660",
      },
    ],
    Revered: [
      {
        name: "Grand Commendation of the Anglers",
        id: "93225",
      },
      {
        name: "Anglers Fishing Raft",
        id: "85500",
      },
      {
        name: "Bipsi's Bobbing Berg",
        id: "107950",
      },
      {
        name: "Dragon Fishing Pole",
        id: "84661",
      },
      {
        name: "Sharpened Tuskarr Spear",
        id: "88535",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Azure Water Strider",
        id: "81354",
      },
      {
        name: "Anglers Tabard",
        id: "89401",
      },
    ],
  },
  "1337": {
    //The Klaxxi
    Honored: [
      {
        name: "Plans: Breastplate of Ancient Steel",
        id: "83791",
      },
      {
        name: "Plans: Gauntlets of Ancient Steel",
        id: "83792",
      },
      {
        name: "Plans: Ghost Iron Shield Spike",
        id: "90531",
      },
      {
        name: "Plans: Ghost Reaver's Breastplate",
        id: "83787",
      },
      {
        name: "Plans: Ghost Reaver's Gauntlets",
        id: "83788",
      },
      {
        name: "Plans: Living Steel Belt Buckle",
        id: "84196",
      },
      {
        name: "Plans: Living Steel Breastplate",
        id: "83789",
      },
      {
        name: "Plans: Living Steel Gauntlets",
        id: "83790",
      },
      {
        name: "Plans: Living Steel Weapon Chain",
        id: "90532",
      },
      {
        name: "Plans: Masterwork Forgewire Axe",
        id: "84197",
      },
      {
        name: "Plans: Masterwork Ghost Shard",
        id: "84198",
      },
      {
        name: "Plans: Masterwork Phantasmal Hammer",
        id: "84217",
      },
      {
        name: "Plans: Masterwork Spiritblade Decimator",
        id: "84218",
      },
      {
        name: "Restorative Amber",
        id: "89230",
      },
    ],
    Revered: [
      {
        name: "Grand Commendation of the Klaxxi",
        id: "92522",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Amber Scorpion",
        id: "85262",
      },
      {
        name: "Amber Espada of Klaxxi'vess",
        id: "89396",
      },
      {
        name: "Amber Flammard of Klaxxi'vess",
        id: "89398",
      },
      {
        name: "Amber Saber of Klaxxi'vess",
        id: "89397",
      },
      {
        name: "Amber Scythe of Klaxxi'vess",
        id: "89395",
      },
      {
        name: "Amber Sledge of Klaxxi'vess",
        id: "89400",
      },
      {
        name: "Amber Slicer of Klaxxi'vess",
        id: "89393",
      },
      {
        name: "Amber Spear of Klaxxi'vess",
        id: "89394",
      },
      {
        name: "Amber Spine of Klaxxi'vess",
        id: "89392",
      },
      {
        name: "Amber Sprayer of Klaxxi'vess",
        id: "89399",
      },
      {
        name: "Klaxxi Tabard",
        id: "89798",
      },
    ],
  },
  "1341": {
    //The August Celestials
    Honored: [
      {
        name: "Celestial Offering",
        id: "89124",
      },
    ],
    Revered: [
      {
        name: "Grand Commendation of the August Celestials",
        id: "93224",
      },
      {
        name: "Formula: Enchant Bracer - Exceptional Strength",
        id: "84561",
      },
      {
        name: "Formula: Enchant Bracer - Greater Agility",
        id: "84557",
      },
      {
        name: "Formula: Enchant Bracer - Super Intellect",
        id: "84559",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Thundering August Cloud Serpent",
        id: "89304",
      },
      {
        name: "Pattern: Royal Satchel",
        id: "86377",
      },
      {
        name: "August Celestials Tabard",
        id: "89799",
      },
    ],
  },
  "1345": {
    //The Lorewalkers
    Revered: [
      {
        name: "Grand Commendation of the Lorewalkers",
        id: "93230",
      },
      {
        name: "Music Roll: Song of Liu Lang",
        id: "122221",
      },
    ],
    Exalted: [
      {
        name: "Disc of the Red Flying Cloud",
        id: "89363",
      },
      {
        name: "Lorewalker's Lodestone",
        id: "87548",
      },
      {
        name: "Lorewalkers Tabard",
        id: "89795",
      },
      {
        name: "Lorewalker's Map",
        id: "87549",
      },
      {
        name: "Mantid Artifact Sonic Locator",
        id: "95509",
      },
      {
        name: "Mantid Artifact Hunter's Kit",
        id: "104198",
      },
    ],
  },
  "1358": {
    //Nat Pagle
    Friend: [
      {
        name: "Nat's Drinking Hat",
        id: "117405",
      },
      {
        name: "Draenic Fishing Pole",
        id: "116826",
      },
      {
        name: "Savage Fishing Pole",
        id: "116825",
      },
    ],
    "Good Friend": [
      {
        name: "Land Shark",
        id: "117404",
      },
    ],
    "Best Friend": [
      {
        name: "Nat's Hat",
        id: "88710",
      },
      {
        name: "Reins of the Crimson Water Strider",
        id: "87791",
      },
    ],
  },
  "1387": {
    //Kirin Tor Offensive
    Friendly: [
      {
        name: "Potion of Light Steps",
        id: "95054",
      },
    ],
    Honored: [
      {
        name: "Belt of Loa Charms",
        id: "95532",
      },
      {
        name: "Cinch of the Dead Forest's Vigil",
        id: "95530",
      },
      {
        name: "Girdle of Shan'ze Glory",
        id: "95529",
      },
      {
        name: "Pain-Binder Girdle",
        id: "95527",
      },
      {
        name: "Rotting Bog Cinch",
        id: "95531",
      },
      {
        name: "Shan'ze Scholar's Girdle",
        id: "95526",
      },
      {
        name: "Skumblade Ritualist Links",
        id: "95534",
      },
      {
        name: "Skumblade-Tooth Girdle",
        id: "95528",
      },
      {
        name: "Thunder Caressed Waistguard",
        id: "95533",
      },
      {
        name: "A Common Rock",
        id: "95053",
      },
      {
        name: "Sleep Dust",
        id: "95093",
      },
    ],
    Revered: [
      {
        name: "Grand Commendation of the Kirin Tor Offensive",
        id: "95545",
      },
      {
        name: "Arcane Propellant",
        id: "95052",
      },
      {
        name: "Frost Rune Trap",
        id: "95055",
      },
    ],
    Exalted: [
      {
        name: "Reins of the Golden Primal Direhorn",
        id: "95564",
      },
      {
        name: "Glorious Standard of the Kirin Tor Offensive",
        id: "95589",
      },
      {
        name: "Kirin Tor Offensive Tabard",
        id: "95591",
      },
      {
        name: "Arcane Trove",
        id: "98560",
      },
      {
        name: "Empty Supply Crate",
        id: "98558",
      },
      {
        name: "Polymorphic Key",
        id: "95056",
      },
    ],
  },
  "1376": {
    //Operation: Shieldwall
    Exalted: [
      {
        name: "Grand Commendation of Operation: Shieldwall",
        id: "93231",
      },
      {
        name: "Grand Armored Gryphon",
        id: "93168",
      },
    ],
  },
  "1435": {
    //Shado-Pan Assault
    Neutral: [
      {
        name: "Destroyer's Battletags",
        id: "95146",
      },
      {
        name: "Flanker's Battletags",
        id: "95143",
      },
      {
        name: "Mender's Battletags",
        id: "95145",
      },
      {
        name: "Striker's Battletags",
        id: "95142",
      },
      {
        name: "Vanguard's Battletags",
        id: "95144",
      },
      {
        name: "Axebinder Wristguards",
        id: "95129",
      },
    ],
    Friendly: [
      {
        name: "Bloodstained Skullsqueezers",
        id: "95111",
      },
      {
        name: "Bonecrusher Bracers",
        id: "95128",
      },
      {
        name: "Bracers of Shielding Thought",
        id: "95135",
      },
      {
        name: "Breastplate of Brutal Strikes",
        id: "95076",
      },
      {
        name: "Brutal Talisman of the Shado-Pan Assault",
        id: "94508",
      },
      {
        name: "Carapace of Segmented Scale",
        id: "95079",
      },
      {
        name: "Charfire Leggings",
        id: "95123",
      },
      {
        name: "Cracklebite Links",
        id: "95087",
      },
      {
        name: "Daggerfinger Clutches",
        id: "95108",
      },
      {
        name: "Darkfang Belt",
        id: "95088",
      },
      {
        name: "Dreamweaver Drape",
        id: "95118",
      },
      {
        name: "Fire Support Robes",
        id: "95081",
      },
      {
        name: "Firestrike Cord",
        id: "95090",
      },
      {
        name: "Flameweaver Handwraps",
        id: "95106",
      },
      {
        name: "Gauntlets of the Longbow",
        id: "95109",
      },
      {
        name: "Ghostbinder Grips",
        id: "95105",
      },
      {
        name: "Gianttooth Chestplate",
        id: "95075",
      },
      {
        name: "Girdle of Glowing Light",
        id: "95091",
      },
      {
        name: "Gloves of Enduring Renewal",
        id: "95107",
      },
      {
        name: "Hauberk of Gleaming Fire",
        id: "95074",
      },
      {
        name: "Hearthfire Armwraps",
        id: "95134",
      },
      {
        name: "Homeguard Leggings",
        id: "95127",
      },
      {
        name: "Kilt of Rising Thunder",
        id: "95126",
      },
      {
        name: "Legguards of Hidden Knives",
        id: "95125",
      },
      {
        name: "Legguards of Renewal",
        id: "95120",
      },
      {
        name: "Longshot Forestcloak",
        id: "95116",
      },
      {
        name: "Loop of the Shado-Pan Assault",
        id: "95141",
      },
      {
        name: "Many-Layered Scalecloak",
        id: "95115",
      },
      {
        name: "Martiean's Splitleaf Girdle",
        id: "95089",
      },
      {
        name: "Nightflight Chain",
        id: "95086",
      },
      {
        name: "Powderburn Bracers",
        id: "95131",
      },
      {
        name: "Raiment of Silent Stars",
        id: "95078",
      },
      {
        name: "Reinforced Spiritplate Girdle",
        id: "95084",
      },
      {
        name: "Ring of the Shado-Pan Assault",
        id: "95139",
      },
      {
        name: "Robes of Misty Bindings",
        id: "95082",
      },
      {
        name: "Roofstalker Shadowwrap",
        id: "95077",
      },
      {
        name: "Seal of the Shado-Pan Assault",
        id: "95137",
      },
      {
        name: "Shadowspike Cloak",
        id: "95117",
      },
      {
        name: "Signet of the Shado-Pan Assault",
        id: "95138",
      },
      {
        name: "Skinsealer Tunic",
        id: "95080",
      },
      {
        name: "Softscar Armplates",
        id: "95130",
      },
      {
        name: "Soothing Talisman of the Shado-Pan Assault",
        id: "94509",
      },
      {
        name: "Spikeshard Greatcloak",
        id: "95114",
      },
      {
        name: "Spiritcaller Cuffs",
        id: "95132",
      },
      {
        name: "Steadfast Talisman of the Shado-Pan Assault",
        id: "94507",
      },
      {
        name: "Stoneward Handguards",
        id: "95110",
      },
      {
        name: "Swordhook Slingbelt",
        id: "95083",
      },
      {
        name: "Thunderbreaker Legplates",
        id: "95119",
      },
      {
        name: "Totemshaper Gloves",
        id: "95112",
      },
      {
        name: "Touch of Soothing Mists",
        id: "95113",
      },
      {
        name: "Troll-Burner Bracers",
        id: "95136",
      },
      {
        name: "Trousers of Waning Shadow",
        id: "95124",
      },
      {
        name: "Vaultbreaker Greaves",
        id: "95121",
      },
      {
        name: "Vicious Talisman of the Shado-Pan Assault",
        id: "94511",
      },
      {
        name: "Volatile Talisman of the Shado-Pan Assault",
        id: "94510",
      },
      {
        name: "Waistplate of Channeled Mending",
        id: "95085",
      },
      {
        name: "Willow-Weave Armbands",
        id: "95133",
      },
      {
        name: "Wisp-Weave Pantaloons",
        id: "95122",
      },
    ],
    Exalted: [
      {
        name: "Frost-Kissed Shoulderwraps",
        id: "95102",
      },
      {
        name: "Halo-Graced Mantle",
        id: "95101",
      },
      {
        name: "Heartroot Shoulderguards",
        id: "95097",
      },
      {
        name: "Lightning Strike Mantle",
        id: "95099",
      },
      {
        name: "Shoulderguards of Potentiation",
        id: "95104",
      },
      {
        name: "Shoulders of Demonic Dreams",
        id: "95096",
      },
      {
        name: "Sightblinder Shoulderguards",
        id: "95098",
      },
      {
        name: "Sparksmasher Pauldrons",
        id: "95103",
      },
      {
        name: "Targetblinder Spaulders",
        id: "95095",
      },
      {
        name: "Wallwalker Spaulders",
        id: "95100",
      },
      {
        name: "Shado-Pan Assault Tabard",
        id: "97131",
      },
    ],
  },
  "1515": {
    //Arakkoa Outcasts
    Friendly: [
      {
        name: "Arakkoa Elixir",
        id: "118666",
      },
    ],
    Honored: [
      {
        name: "Saberon Protector",
        id: "118682",
      },
    ],
    Revered: [
      {
        name: "Son of Sethe",
        id: "119143",
      },
      {
        name: "Trade Agreement: Arakkoa Outcasts",
        id: "128294",
      },
      {
        name: "Wings of the Outcasts",
        id: "118698",
      },
    ],
    Exalted: [
      {
        name: "Shadowmane Charger",
        id: "116772",
      },
      {
        name: "Arakkoa Outcasts Tabard",
        id: "119136",
      },
      {
        name: "Draenor Archaeologist's Lodestone",
        id: "117389",
      },
      {
        name: "Draenor Archaeologist's Map",
        id: "117390",
      },
    ],
  },
  "1682": {
    //Wrynn's Vanguard
    Friendly: [
      {
        name: "Disposable Pocket Flying Machine",
        id: "114126",
      },
      {
        name: "Primal Combatant's Badge of Adaptation",
        id: "115521",
      },
      {
        name: "Wrynn's Vanguard Battle Standard",
        id: "115513",
      },
    ],
    Honored: [
      {
        name: "Flimsy X-Ray Goggles",
        id: "115532",
      },
      {
        name: "Swift Riding Crop",
        id: "115522",
      },
    ],
    Revered: [
      {
        name: "LeBlanc's Recorder",
        id: "116396",
      },
      {
        name: "Primal Gladiator's Badge of Adaptation",
        id: "115496",
      },
    ],
    Exalted: [
      {
        name: "Pale Thorngrazer",
        id: "116776",
      },
      {
        name: "Wrynn's Vanguard Tabard",
        id: "115517",
      },
    ],
  },
  "1710": {
    //Sha'tari Defense
    Friendly: [
      {
        name: "Sha'tari Elixir",
        id: "118669",
      },
    ],
    Honored: [
      {
        name: "Sha'tari Protector",
        id: "118685",
      },
      {
        name: "Soul Evacuation Crystal",
        id: "119182",
      },
    ],
    Revered: [
      {
        name: "Contract: Vindicator Heluun",
        id: "119167",
      },
      {
        name: "Ensemble: Sha'tari Defender's Plate",
        id: "158208",
      },
      {
        name: "Sha'tari Defender's Medallion",
        id: "119421",
      },
      {
        name: "Sky Fry",
        id: "119150",
      },
    ],
    Exalted: [
      {
        name: "Armored Irontusk",
        id: "116665",
      },
      {
        name: "Sha'tari Defense Tabard",
        id: "119140",
      },
    ],
  },
  "1711": {
    //Steamwheedle Preservation Society
    Friendly: [
      {
        name: "Steamwheedle Elixir",
        id: "118667",
      },
    ],
    Honored: [
      {
        name: "Portable Goon Squad",
        id: "118683",
      },
      {
        name: "Goblin Hot Potato",
        id: "110426",
      },
    ],
    Revered: [
      {
        name: "Contract: Professor Felblast",
        id: "119165",
      },
      {
        name: "Captured Forest Sproutling",
        id: "119149",
      },
      {
        name: "Indentured Albino River Calf",
        id: "119148",
      },
    ],
    Exalted: [
      {
        name: "Domesticated Razorback",
        id: "116672",
      },
      {
        name: 'Steamwheedle "Preservation" Society Tabard',
        id: "119137",
      },
    ],
  },
  "1731": {
    //Council of Exarchs
    Friendly: [
      {
        name: "Exarch Elixir",
        id: "118665",
      },
    ],
    Honored: [
      {
        name: "Permanent Time Bubble",
        id: "115472",
      },
      {
        name: "Tranquility of the Exarchs",
        id: "118680",
      },
    ],
    Revered: [
      {
        name: "Contract: Cleric Maluuf",
        id: "119162",
      },
      {
        name: "Relic of Karabor",
        id: "118663",
      },
      {
        name: "Draenei Micro Defender",
        id: "119142",
      },
    ],
    Exalted: [
      {
        name: "Dusty Rockhide",
        id: "116664",
      },
      {
        name: "Council of Exarchs Tabard",
        id: "119135",
      },
    ],
  },
  "1828": {
    //Highmountain Tribe
    Friendly: [
      {
        name: "Whitewater Carp",
        id: "131814",
      },
      {
        name: "Bloodtotem War Harness",
        id: "140333",
      },
      {
        name: "Boon of the Butcher",
        id: "140215",
      },
    ],
    Honored: [
      {
        name: "Design: Skystone Loop",
        id: "137839",
      },
      {
        name: "Recipe: Gleaming Iron Spike",
        id: "136697",
      },
      {
        name: "Rivermane War Harness",
        id: "140332",
      },
      {
        name: "Treasure Map: Highmountain",
        id: "140731",
      },
      {
        name: "Windfall Totem",
        id: "140330",
      },
      {
        name: "Baby Elderhorn",
        id: "136919",
      },
    ],
    Revered: [
      {
        name: "Darkshard Fragment",
        id: "131812",
      },
      {
        name: "Harpy-Hunter's Gloves",
        id: "139601",
      },
      {
        name: "Log",
        id: "140655",
      },
      {
        name: "Recipe: Drums of the Mountain",
        id: "142408",
      },
      {
        name: "Skyhorn War Harness",
        id: "140331",
      },
    ],
    Exalted: [
      {
        name: "Mountainforged Chain Hauberk",
        id: "139597",
      },
      {
        name: "Tabard of the Highmountain Tribe",
        id: "140576",
      },
      {
        name: "Totem Tote",
        id: "140335",
      },
      {
        name: "Design: Ancient Maelstrom Amulet",
        id: "137855",
      },
      {
        name: "Design: Dawnlight Band",
        id: "137846",
      },
      {
        name: "Design: Prophetic Band",
        id: "137844",
      },
      {
        name: "Highmountain War Harness",
        id: "140334",
      },
      {
        name: "Recipe: Demonsteel Armguards",
        id: "123948",
      },
      {
        name: "Recipe: Demonsteel Breastplate",
        id: "123955",
      },
      {
        name: "Recipe: Demonsteel Gauntlets",
        id: "123953",
      },
      {
        name: "Recipe: Drums of the Mountain",
        id: "142409",
      },
    ],
  },
  "1847": {
    //Hand of the Prophet
    Friendly: [
      {
        name: "Hand of the Prophet Battle Standard",
        id: "128452",
      },
      {
        name: "Ship Blueprint: Battleship",
        id: "128492",
      },
    ],
    Honored: [
      {
        name: "Contract: Dowser Bigspark",
        id: "128445",
      },
      {
        name: "Equipment Blueprint: Tuskarr Fishing Net",
        id: "128491",
      },
      {
        name: "Treasure Map: Tanaan Jungle",
        id: "128474",
      },
    ],
    Revered: [
      {
        name: "Ensemble: Ceremonial Karabor Finery",
        id: "128473",
      },
      {
        name: "Equipment Blueprint: Unsinkable",
        id: "128250",
      },
      {
        name: "Karabor Councilor's Attire",
        id: "128462",
      },
      {
        name: "Blueprint: Oil Rig",
        id: "128444",
      },
    ],
    Exalted: [
      {
        name: "Deathtusk Felboar",
        id: "128527",
      },
      {
        name: "Empowered Augment Rune",
        id: "128482",
      },
    ],
  },
  "1850": {
    //The Saberstalkers
    Friendly: [
      {
        name: "Saberstalkers Battle Standard",
        id: "128453",
      },
    ],
    Honored: [
      {
        name: "Contract: Pallas",
        id: "128439",
      },
      {
        name: "Wild Goretusk",
        id: "116671",
      },
    ],
    Revered: [
      {
        name: "Saberstalker Teachings: Trailblazer",
        id: "128446",
      },
      {
        name: "Savage Cub",
        id: "128477",
      },
    ],
    Exalted: [
      {
        name: "Bristling Hellboar",
        id: "128481",
      },
      {
        name: "Saberstalkers Tabard",
        id: "128449",
      },
    ],
  },
  "1859": {
    //Nightfallen
    Friendly: [
      {
        name: "Scavenged Felsoul Sabatons",
        id: "140015",
      },
      {
        name: "Footpads of the Nightrunners",
        id: "139979",
      },
      {
        name: "Irongrove Refugee Boots",
        id: "121738",
      },
      {
        name: "Nightfall Slippers",
        id: "121736",
      },
    ],
    Honored: [
      {
        name: "Braided Manastring Cinch",
        id: "139600",
      },
      {
        name: "Mobile Telemancy Beacon",
        id: "140324",
      },
      {
        name: "Boon of the Manaseeker",
        id: "140218",
      },
      {
        name: "Treasure Map: Suramar",
        id: "140746",
      },
      {
        name: "ilvl 820 Class Hall Set Gloves",
      },
      {
        name: "830-840 Class Hall set upgrade tokens",
      },
    ],
    Revered: [
      {
        name: "Mana-Soaked Amethyst Pendant",
        id: "139604",
      },
      {
        name: "Home Made Party Mask",
        id: "140325",
      },
      {
        name: "Recipe: Potion of Prolonged Power",
        id: "142120",
      },
      {
        name: "Extinguished Eye",
        id: "136899",
      },
    ],
    Exalted: [
      {
        name: "Nightfallen Tabard",
        id: "140575",
      },
      {
        name: "Formula: Enchant Ring - Binding of Versatility",
        id: "128603",
      },
      {
        name: "Pattern: Imbued Silkweave Slippers",
        id: "137979",
      },
      {
        name: "Pattern: Imbued Silkweave Bracers",
        id: "137973",
      },
      {
        name: "Design: Subtle Shadowruby Pendant",
        id: "137850",
      },
      {
        name: "Formula: Enchant Ring - Binding of Critical Strike",
        id: "128600",
      },
      {
        name: "Formula: Enchant Cloak - Binding of Intellect",
        id: "128609",
      },
      {
        name: "Pattern: Imbued Silkweave Pantaloons",
        id: "137976",
      },
      {
        name: "Formula: Enchant Ring - Binding of Mastery",
        id: "128602",
      },
    ],
  },
  "1894": {
    //The Wardens
    Honored: [
      {
        name: "Trapped Treasure Chest Kit",
        id: "130191",
      },
      {
        name: "Recipe: Feathered Luffa",
        id: "142333",
      },
      {
        name: "Schematic: Bolt-Action Headgun",
        id: "137713",
      },
      {
        name: "Schematic: Reinforced Headgun",
        id: "137714",
      },
    ],
    Revered: [
      {
        name: "Boon of the Bloodhunter",
        id: "140219",
      },
      {
        name: "Fledgling Warden Owl",
        id: "136898",
      },
      {
        name: "Syxsehnz Rod",
        id: "130157",
      },
      {
        name: "Vault Patroller's Warboots",
        id: "139603",
      },
      {
        name: "Recipe: Spiced Falcosaur Omelet",
        id: "142331",
      },
      {
        name: "Vantus Rune Technique: Cenarius",
        id: "137773",
      },
      {
        name: "Vantus Rune Technique: Odyn",
        id: "142107",
      },
      {
        name: "Vantus Rune Technique: Return to Karazhan (Upper)",
        id: "146385",
      },
      {
        name: "Vantus Rune Technique: Spellblade Aluriel",
        id: "137777",
      },
      {
        name: "Vantus Rune Technique: Star Augur Etraeus",
        id: "137781",
      },
      {
        name: "Vantus Rune Technique: The Arcway",
        id: "146381",
      },
    ],
    Exalted: [
      {
        name: "Drape of the Patient Hunter",
        id: "139607",
      },
      {
        name: "Warden's Tabard",
        id: "140580",
      },
      {
        name: "Design: Twisted Pandemonite Choker",
        id: "137849",
      },
      {
        name: "Schematic: Sawed-Off Cranial Cannon",
        id: "137716",
      },
      {
        name: "Schematic: Semi-Automagic Cranial Cannon",
        id: "137715",
      },
    ],
  },
  "1900": {
    //Court of Farondis
    Honored: [
      {
        name: "Enchanted Stone Whistle",
        id: "129279",
      },
      {
        name: "Pattern: Silkweave Pantaloons",
        id: "138015",
      },
      {
        name: "Treasure Map: Azsuna",
        id: "140744",
      },
      {
        name: "Vainglorious Draught",
        id: "139631",
      },
    ],
    Revered: [
      {
        name: "Syriel Crescentfall's Notes: Ravenguard",
        id: "139556",
      },
      {
        name: "Beginner's Guide to Dimensional Rifting",
        id: "129276",
      },
      {
        name: "Boon of the Gemfinder",
        id: "140213",
      },
      {
        name: "Cadet's Gaudy Scarf",
        id: "139606",
      },
      {
        name: "Court Scribe",
        id: "140672",
      },
      {
        name: "Vantus Rune Technique: Cathedral of Eternal Night",
        id: "146384",
      },
      {
        name: "Vantus Rune Technique: High Botanist Tel'arn",
        id: "137779",
      },
      {
        name: "Vantus Rune Technique: Krosus",
        id: "137780",
      },
      {
        name: "Vantus Rune Technique: Skorpyron",
        id: "137774",
      },
      {
        name: "Vantus Rune Technique: Trilliax",
        id: "137776",
      },
      {
        name: "Vantus Rune Technique: Vault of the Wardens",
        id: "146382",
      },
    ],
    Exalted: [
      {
        name: "Once-Fashionable Nar'thalas Leggings",
        id: "139595",
      },
      {
        name: "Court of Farondis Tabard",
        id: "140577",
      },
      {
        name: "Pattern: Imbued Silkweave Gloves",
        id: "137978",
      },
      {
        name: "Pattern: Imbued Silkweave Hood",
        id: "137977",
      },
      {
        name: "Pattern: Imbued Silkweave Robe",
        id: "137980",
      },
    ],
  },
  "1975": {
    //Conjurer Margoss
    Friend: [
      {
        name: "Murloc Bobber",
        id: "136376",
      },
      {
        name: "Squeaky Duck Bobber",
        id: "136375",
      },
      {
        name: "Crate of Bobbers: Squeaky Duck",
        id: "142531",
      },
      {
        name: "Crate of Bobbers: Murloc Head",
        id: "142532",
      },
    ],
    "Good Friend": [
      {
        name: "Sting Ray Pup",
        id: "138810",
      },
    ],
    "Best Friend": [
      {
        name: "Brinedeep Bottom-Feeder",
        id: "138811",
      },
      {
        name: "Trashy",
        id: "143842",
      },
    ],
  },
  "2103": {
    //Zandalari Empire
    Honored: [
      {
        name: "Dinomancer's Cloak",
        id: "160530",
      },
      {
        name: "Recipe: Contract: Zandalari Empire",
        id: "162753",
      },
      {
        name: "Schematic: AZ3-R1-T3 Gearspun Goggles",
        id: "162730",
      },
    ],
    Revered: [
      {
        name: "Bindings of the Disparate Tribes",
        id: "161516",
      },
      {
        name: "Cord of the Loa Worshippers",
        id: "161497",
      },
      {
        name: "Gral Worshipper's Waders",
        id: "161526",
      },
      {
        name: "Grand Fleet Legplates",
        id: "161546",
      },
      {
        name: "Party Totem",
        id: "163210",
      },
      {
        name: "Tome of Hex: Zandalari Tendonripper",
        id: "162623",
      },
      {
        name: "Tome of Polymorph: Direhorn",
        id: "162625",
      },
      {
        name: "Design: Laribole Staff of Alacrity",
        id: "162764",
      },
      {
        name: "Design: Owlseye Loop",
        id: "162761",
      },
      {
        name: "Design: Scarlet Diamond Staff of Intuition",
        id: "162765",
      },
      {
        name: "Formula: Enchant Ring - Pact of Haste",
        id: "162717",
      },
      {
        name: "Formula: Enchant Weapon - Deadly Navigation",
        id: "162722",
      },
      {
        name: "Formula: Enchant Weapon - Stalwart Navigation",
        id: "162721",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak of Resilience",
        id: "162771",
      },
      {
        name: "Recipe: Battle Potion of Stamina",
        id: "163317",
      },
      {
        name: "Recipe: Coastal Healing Potion",
        id: "162704",
      },
      {
        name: "Recipe: Flask of Endless Fathoms",
        id: "162696",
      },
      {
        name: "Recipe: Potion of Replenishment",
        id: "162692",
      },
      {
        name: "Schematic: AZ3-R1-T3 Gearspun Goggles",
        id: "162731",
      },
      {
        name: "Schematic: Deployable Attire Rearranger",
        id: "162743",
      },
      {
        name: "Schematic: Interdimensional Companion Repository",
        id: "162341",
      },
      {
        name: "Schematic: Organic Discombobulation Grenade",
        id: "162741",
      },
    ],
    Exalted: [
      {
        name: "Gonk Adherents Vambraces",
        id: "161548",
      },
      {
        name: "Reins of the Armored Albino Pterrordax",
        id: "161667",
      },
      {
        name: "Reins of the Armored Ebony Pterrordax",
        id: "161664",
      },
      {
        name: "Reins of the Armored Orange Pterrordax",
        id: "161666",
      },
      {
        name: "Reins of the Armored Cobalt Pterrordax",
        id: "161665",
      },
      {
        name: "Shadraspun Legwraps",
        id: "161500",
      },
      {
        name: "Torcali's Grips of the Bounty",
        id: "161524",
      },
      {
        name: "Waistcord of Pa'ku's Flight",
        id: "161517",
      },
      {
        name: "Tabard of the Zandalari Empire",
        id: "160546",
      },
    ],
  },
  "2156": {
    //Talanji's Expedition
    Honored: [
      {
        name: "Drape of the Blood Purge",
        id: "160531",
      },
      {
        name: "Recipe: Contract: Talanji's Expedition",
        id: "162754",
      },
      {
        name: "Schematic: AZ3-R1-T3 Bionic Bifocals",
        id: "162732",
      },
    ],
    Revered: [
      {
        name: "Gloomplate Gauntlets",
        id: "161538",
      },
      {
        name: "Grips of the Swamp Hunter",
        id: "161528",
      },
      {
        name: "Swamp Medic's Leggings",
        id: "161493",
      },
      {
        name: "Swampstalker Footpads",
        id: "161506",
      },
      {
        name: "Tragg the Curious",
        id: "163501",
      },
      {
        name: "Design: Tidal Amethyst Loop",
        id: "162762",
      },
      {
        name: "Formula: Enchant Ring - Pact of Mastery",
        id: "162718",
      },
      {
        name: "Formula: Enchant Weapon - Masterful Navigation",
        id: "162724",
      },
      {
        name: "Formula: Enchant Weapon - Siphoning",
        id: "162723",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak of the Feather",
        id: "162770",
      },
      {
        name: "Recipe: Battle Potion of Intellect",
        id: "163315",
      },
      {
        name: "Recipe: Coastal Mana Potion",
        id: "162703",
      },
      {
        name: "Recipe: Flask of the Vast Horizon",
        id: "162697",
      },
      {
        name: "Recipe: Hardened Tempest Knuckles",
        id: "162768",
      },
      {
        name: "Recipe: Mistscale Knuckles",
        id: "162767",
      },
      {
        name: "Recipe: Potion of Bursting Blood",
        id: "162693",
      },
      {
        name: "Schematic: AZ3-R1-T3 Bionic Bifocals",
        id: "162733",
      },
      {
        name: "Schematic: Charged Refrigeration Projectile",
        id: "162738",
      },
      {
        name: "Schematic: Fro-Grow Grenade",
        id: "162739",
      },
      {
        name: "Schematic: Neurological Immobilization Bomb",
        id: "162740",
      },
    ],
    Exalted: [
      {
        name: "Hir'eek Hide Leggings",
        id: "161520",
      },
      {
        name: "Reins of the Expedition Bloodswarmer",
        id: "161774",
      },
      {
        name: "Torga Scale Girdle",
        id: "161536",
      },
      {
        name: "Zo'bal Spirit Gloves",
        id: "161492",
      },
      {
        name: "Zul'jan Camp Stalkers",
        id: "161551",
      },
      {
        name: "Talanji's Expedition Tabard",
        id: "160547",
      },
    ],
  },
  "2157": {
    //The Honorbound
    Honored: [
      {
        name: "Schematic: AZ3-R1-T3 Synthetic Specs",
        id: "162728",
      },
      {
        name: "Scouting Report: Swiftwind Post",
        id: "162536",
      },
      {
        name: "Scouting Report: Wolf's Den",
        id: "162530",
      },
      {
        name: "Drape of the Horde's Fury",
        id: "160532",
      },
    ],
    Revered: [
      {
        name: "Goblin Sapper's Legguards",
        id: "161521",
      },
      {
        name: "Sabatons of the Renewed Warpath",
        id: "161537",
      },
      {
        name: "Silent Stalker Belt",
        id: "161512",
      },
      {
        name: "Wristwraps of the Dutiful Apothecary",
        id: "161498",
      },
      {
        name: "Lil' War Machine",
        id: "163779",
      },
      {
        name: "Design: Amberblaze Loop",
        id: "162760",
      },
      {
        name: "Formula: Enchant Ring - Pact of Critical Strike",
        id: "162716",
      },
      {
        name: "Recipe: Battle Potion of Strength",
        id: "163319",
      },
      {
        name: "Recipe: Enchanter's Sorcerous Scepter",
        id: "162720",
      },
      {
        name: "Recipe: Endless Tincture of Fractional Power",
        id: "162701",
      },
      {
        name: "Recipe: Flask of the Currents",
        id: "162695",
      },
      {
        name: "Recipe: Steelskin Potion",
        id: "162691",
      },
      {
        name: "Recipe: Stormsteel Dagger",
        id: "162707",
      },
      {
        name: "Recipe: Stormsteel Saber",
        id: "162774",
      },
      {
        name: "Recipe: Stormsteel Shield",
        id: "162706",
      },
      {
        name: "Recipe: Stormsteel Spear",
        id: "162708",
      },
      {
        name: "Recipe: Surging Alchemist Stone",
        id: "162702",
      },
      {
        name: "Schematic: AZ3-R1-T3 Synthetic Specs",
        id: "162729",
      },
      {
        name: "Schematic: Finely-Tuned Stormsteel Destroyer",
        id: "162746",
      },
      {
        name: "Schematic: Monelite Scope of Alacrity",
        id: "162744",
      },
      {
        name: "Schematic: Precision Attitude Adjuster",
        id: "162745",
      },
      {
        name: "Scouting Report: Hillcrest Pasture",
        id: "162533",
      },
      {
        name: "Scouting Report: Mudfisher Cove",
        id: "162535",
      },
      {
        name: "Scouting Report: Stonefist Watch",
        id: "162531",
      },
      {
        name: "Scouting Report: Stonetusk Watch",
        id: "162534",
      },
      {
        name: "Scouting Report: Windfall Cavern",
        id: "162532",
      },
    ],
    Exalted: [
      {
        name: "Bindings of the Elemental Allies",
        id: "161532",
      },
      {
        name: "Lustful Warbringer's Legplates",
        id: "161550",
      },
      {
        name: "Tranquil Health Weavers",
        id: "161509",
      },
      {
        name: "Witch Doctor's Slippers",
        id: "161501",
      },
      {
        name: "Tabard of the Honorbound",
        id: "160545",
      },
    ],
  },
  "2158": {
    //Voldunai
    Honored: [
      {
        name: "Akunda's Firesticks",
        id: "163211",
      },
      {
        name: "Desert Flute",
        id: "159753",
      },
      {
        name: "Dune Shroud",
        id: "160529",
      },
      {
        name: "Vulpera Battle Banner",
        id: "163566",
      },
      {
        name: "Recipe: Contract: Voldunai",
        id: "162755",
      },
      {
        name: "Schematic: AZ3-R1-T3 Orthogonal Optics",
        id: "162734",
      },
    ],
    Revered: [
      {
        name: "Alpaca Wool Gloves",
        id: "161489",
      },
      {
        name: "Bracers of Zem'lan",
        id: "161527",
      },
      {
        name: "Girdle of Scavenged Plates",
        id: "161545",
      },
      {
        name: "Silent Scavenger Leggings",
        id: "161507",
      },
      {
        name: "Ghostly Explorer's Skull",
        id: "163213",
      },
      {
        name: "Pack of Many Pockets",
        id: "161999",
      },
      {
        name: "Vulpera Scrapper's Armor",
        id: "163565",
      },
      {
        name: "Design: Royal Quartz Loop",
        id: "162763",
      },
      {
        name: "Formula: Enchant Ring - Pact of Versatility",
        id: "162719",
      },
      {
        name: "Formula: Enchant Weapon - Gale-Force Striking",
        id: "162725",
      },
      {
        name: "Formula: Enchant Weapon - Versatile Navigation",
        id: "162726",
      },
      {
        name: "Pattern: Deep Sea Bag",
        id: "162769",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak",
        id: "162772",
      },
      {
        name: "Recipe: Battle Potion of Agility",
        id: "163313",
      },
      {
        name: "Recipe: Coastal Rejuvenation Potion",
        id: "162705",
      },
      {
        name: "Recipe: Flask of the Undertow",
        id: "162698",
      },
      {
        name: "Recipe: Potion of Rising Death",
        id: "162694",
      },
      {
        name: "Recipe: Recurve Bow of the Strands",
        id: "162766",
      },
      {
        name: "Schematic: AZ3-R1-T3 Orthogonal Optics",
        id: "162735",
      },
      {
        name: "Schematic: Catchroot Seed Spreader",
        id: "162737",
      },
      {
        name: "Schematic: Frost-Laced Ammunition",
        id: "162727",
      },
      {
        name: "Schematic: Thermo-Accelerated Plague Spreader",
        id: "162736",
      },
    ],
    Exalted: [
      {
        name: "Akunda's Grounding Boots",
        id: "161531",
      },
      {
        name: "Gauntlets of the Scorched Sands",
        id: "161542",
      },
      {
        name: "Kimbul's Cuffs of Redemption",
        id: "161511",
      },
      {
        name: "Reins of the Alabaster Hyena",
        id: "161773",
      },
      {
        name: "Sash of the Scaled Devoted",
        id: "161502",
      },
      {
        name: "Tabard of the Voldunai",
        id: "160548",
      },
    ],
  },
  "2159": {
    //7th Legion
    Honored: [
      {
        name: "Schematic: AZ3-R1-T3 Synthetic Specs",
        id: "162323",
      },
      {
        name: "Scouting Report: Grimwatt's Crash",
        id: "163043",
      },
      {
        name: "Scouting Report: Veiled Grotto",
        id: "163048",
      },
      {
        name: "7th Legionnaire's Cloak",
        id: "160536",
      },
    ],
    Revered: [
      {
        name: "Belt of the Grove Auxiliary",
        id: "161586",
      },
      {
        name: "Legguards of the Storm Battalion",
        id: "161587",
      },
      {
        name: "Royal Knight's Sabatons",
        id: "161589",
      },
      {
        name: "Warmage's Flame-Brimming Wristcords",
        id: "161583",
      },
      {
        name: "Lil' Siege Tower",
        id: "163778",
      },
      {
        name: "Design: Amberblaze Loop",
        id: "162378",
      },
      {
        name: "Formula: Enchant Ring - Pact of Critical Strike",
        id: "162302",
      },
      {
        name: "Recipe: Battle Potion of Strength",
        id: "163320",
      },
      {
        name: "Recipe: Enchanter's Sorcerous Scepter",
        id: "162306",
      },
      {
        name: "Recipe: Endless Tincture of Fractional Power",
        id: "162138",
      },
      {
        name: "Recipe: Flask of the Currents",
        id: "162132",
      },
      {
        name: "Recipe: Steelskin Potion",
        id: "162128",
      },
      {
        name: "Recipe: Stormsteel Dagger",
        id: "162275",
      },
      {
        name: "Recipe: Stormsteel Saber",
        id: "162670",
      },
      {
        name: "Recipe: Stormsteel Shield",
        id: "162261",
      },
      {
        name: "Recipe: Stormsteel Spear",
        id: "162276",
      },
      {
        name: "Recipe: Surging Alchemist Stone",
        id: "162139",
      },
      {
        name: "Schematic: AZ3-R1-T3 Synthetic Specs",
        id: "162324",
      },
      {
        name: "Schematic: Finely-Tuned Stormsteel Destroyer",
        id: "162346",
      },
      {
        name: "Schematic: Monelite Scope of Alacrity",
        id: "162344",
      },
      {
        name: "Schematic: Precision Attitude Adjuster",
        id: "162345",
      },
      {
        name: "Scouting Report: Mistvine Ledge",
        id: "163044",
      },
      {
        name: "Scouting Report: Mugamba Overlook",
        id: "163047",
      },
      {
        name: "Scouting Report: Verdant Hollow",
        id: "163046",
      },
      {
        name: "Scouting Report: Vulture's Nest",
        id: "163041",
      },
    ],
    Exalted: [
      {
        name: "Bracers of the Allied Earthbinders",
        id: "161588",
      },
      {
        name: "Elite Footman's Legplates",
        id: "161590",
      },
      {
        name: "Gloves of the Forward Skirmisher",
        id: "161585",
      },
      {
        name: "Medical Corps Slippers",
        id: "161584",
      },
      {
        name: "Tabard of the 7th Legion",
        id: "160539",
      },
    ],
  },
  "2160": {
    //Proudmoore Admiralty
    Honored: [
      {
        name: "Gnoll Targetting Barrel",
        id: "163201",
      },
      {
        name: "Navy Blue Boat Cloak",
        id: "160534",
      },
      {
        name: "Recipe: Contract: Proudmoore Admiralty",
        id: "162359",
      },
      {
        name: "Schematic: AZ3-R1-T3 Bionic Bifocals",
        id: "162327",
      },
    ],
    Revered: [
      {
        name: "Master-At-Arms Wristguards",
        id: "161569",
      },
      {
        name: "Proudmoore Marine's Legplates",
        id: "161573",
      },
      {
        name: "Sailing Master's Sash",
        id: "161567",
      },
      {
        name: "Yardarm Sharpshooter's Boots",
        id: "161572",
      },
      {
        name: "Admiralty Marine's Duffel",
        id: "161976",
      },
      {
        name: "Cursed Spyglass",
        id: "163200",
      },
      {
        name: "Design: Tidal Amethyst Loop",
        id: "162380",
      },
      {
        name: "Formula: Enchant Ring - Pact of Mastery",
        id: "162304",
      },
      {
        name: "Formula: Enchant Weapon - Masterful Navigation",
        id: "162317",
      },
      {
        name: "Formula: Enchant Weapon - Siphoning",
        id: "162316",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak of the Feather",
        id: "162424",
      },
      {
        name: "Recipe: Battle Potion of Intellect",
        id: "163316",
      },
      {
        name: "Recipe: Coastal Mana Potion",
        id: "162254",
      },
      {
        name: "Recipe: Flask of the Vast Horizon",
        id: "162134",
      },
      {
        name: "Recipe: Hardened Tempest Knuckles",
        id: "162414",
      },
      {
        name: "Recipe: Mistscale Knuckles",
        id: "162413",
      },
      {
        name: "Recipe: Potion of Bursting Blood",
        id: "162130",
      },
      {
        name: "Schematic: AZ3-R1-T3 Bionic Bifocals",
        id: "162328",
      },
      {
        name: "Schematic: Charged Refrigeration Projectile",
        id: "162334",
      },
      {
        name: "Schematic: Fro-Grow Grenade",
        id: "162335",
      },
      {
        name: "Schematic: Neurological Immobilization Bomb",
        id: "162336",
      },
    ],
    Exalted: [
      {
        name: "Boarding Action Vambraces",
        id: "161574",
      },
      {
        name: "Cordage Sliding Grips",
        id: "161571",
      },
      {
        name: "First Lieutenant's Ceremonial Belt",
        id: "161570",
      },
      {
        name: "Maritime Spellweaver's Leggings",
        id: "161568",
      },
      {
        name: "Reins of the Admiralty Stallion",
        id: "161911",
      },
      {
        name: "Reins of the Proudmoore Sea Scout",
        id: "161879",
      },
      {
        name: "Proudmoore Admiralty Tabard",
        id: "160540",
      },
    ],
  },
  "2161": {
    //Order of Embers
    Honored: [
      {
        name: "Witch Hunter's Cape",
        id: "160535",
      },
      {
        name: "Ghostly Pet Biscuit",
        id: "163205",
      },
      {
        name: "Recipe: Contract: Order of Embers",
        id: "162361",
      },
      {
        name: "Schematic: AZ3-R1-T3 Orthogonal Optics",
        id: "162329",
      },
    ],
    Revered: [
      {
        name: "Footpads of the Deft Exorcist",
        id: "161593",
      },
      {
        name: "Gauntlets of Renewed Resolution",
        id: "161597",
      },
      {
        name: "Grips of the Oathsworn",
        id: "161595",
      },
      {
        name: "Leggings of Devout Opposition",
        id: "161592",
      },
      {
        name: "Tome of Hex: Wicker Mongrel",
        id: "162624",
      },
      {
        name: "Weary Spirit Binding",
        id: "163206",
      },
      {
        name: "Pristine Falcon Feather",
        id: "163491",
      },
      {
        name: "Design: Royal Quartz Loop",
        id: "162381",
      },
      {
        name: "Formula: Enchant Ring - Pact of Versatility",
        id: "162305",
      },
      {
        name: "Formula: Enchant Weapon - Gale-Force Striking",
        id: "162318",
      },
      {
        name: "Formula: Enchant Weapon - Versatile Navigation",
        id: "162320",
      },
      {
        name: "Pattern: Deep Sea Bag",
        id: "162421",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak",
        id: "162427",
      },
      {
        name: "Recipe: Battle Potion of Agility",
        id: "163314",
      },
      {
        name: "Recipe: Coastal Rejuvenation Potion",
        id: "162256",
      },
      {
        name: "Recipe: Flask of the Undertow",
        id: "162135",
      },
      {
        name: "Recipe: Potion of Rising Death",
        id: "162131",
      },
      {
        name: "Recipe: Recurve Bow of the Strands",
        id: "162412",
      },
      {
        name: "Schematic: AZ3-R1-T3 Orthogonal Optics",
        id: "162330",
      },
      {
        name: "Schematic: Catchroot Seed Spreader",
        id: "162333",
      },
      {
        name: "Schematic: Frost-Laced Ammunition",
        id: "162322",
      },
    ],
    Exalted: [
      {
        name: "Boarding Action Vambraces",
        id: "161574",
      },
      {
        name: "Cordage Sliding Grips",
        id: "161571",
      },
      {
        name: "First Lieutenant's Ceremonial Belt",
        id: "161570",
      },
      {
        name: "Maritime Spellweaver's Leggings",
        id: "161568",
      },
      {
        name: "Reins of the Admiralty Stallion",
        id: "161911",
      },
      {
        name: "Reins of the Proudmoore Sea Scout",
        id: "161879",
      },
      {
        name: "Proudmoore Admiralty Tabard",
        id: "160540",
      },
    ],
  },
  "2162": {
    //Storms Wake
    Honored: [
      {
        name: "Cape of the Divine Depths",
        id: "160533",
      },
      {
        name: "Recipe: Contract: Storm's Wake",
        id: "162363",
      },
      {
        name: "Schematic: AZ3-R1-T3 Gearspun Goggles",
        id: "162325",
      },
    ],
    Revered: [
      {
        name: "Bindings of the Seacallers",
        id: "161579",
      },
      {
        name: "Handwraps of Deep Faith",
        id: "161575",
      },
      {
        name: "Keelbreak Girdle",
        id: "161582",
      },
      {
        name: "Seabinder's Leggings",
        id: "161577",
      },
      {
        name: "Dead Ringer",
        id: "163463",
      },
      {
        name: "Tome of Polymorph: Bumblebee",
        id: "162626",
      },
      {
        name: "Pair of Bee Wings",
        id: "163490",
      },
      {
        name: "Design: Laribole Staff of Alacrity",
        id: "162382",
      },
      {
        name: "Design: Owlseye Loop",
        id: "162379",
      },
      {
        name: "Design: Scarlet Diamond Staff of Intuition",
        id: "162385",
      },
      {
        name: "Formula: Enchant Ring - Pact of Haste",
        id: "162303",
      },
      {
        name: "Formula: Enchant Weapon - Deadly Navigation",
        id: "162313",
      },
      {
        name: "Formula: Enchant Weapon - Stalwart Navigation",
        id: "162312",
      },
      {
        name: "Pattern: Embroidered Deep Sea Cloak of Resilience",
        id: "162426",
      },
      {
        name: "Recipe: Battle Potion of Stamina",
        id: "163318",
      },
      {
        name: "Recipe: Coastal Healing Potion",
        id: "162255",
      },
      {
        name: "Recipe: Flask of Endless Fathoms",
        id: "162133",
      },
      {
        name: "Recipe: Potion of Replenishment",
        id: "162129",
      },
      {
        name: "Schematic: AZ3-R1-T3 Gearspun Goggles",
        id: "162326",
      },
      {
        name: "Schematic: Deployable Attire Rearranger",
        id: "162342",
      },
      {
        name: "Schematic: Interdimensional Companion Repository",
        id: "162341",
      },
      {
        name: "Schematic: Organic Discombobulation Grenade",
        id: "162337",
      },
    ],
    Exalted: [
      {
        name: "Kelp-Encrusted Bindings",
        id: "161578",
      },
      {
        name: "Reins of the Dapple Gray",
        id: "161912",
      },
      {
        name: "Reins of the Stormsong Coastwatcher",
        id: "161909",
      },
      {
        name: "Sea Priest's Waistcord",
        id: "161576",
      },
      {
        name: "Stormbreaker Galoshes",
        id: "161580",
      },
      {
        name: "Tidecrest Gauntlets",
        id: "161581",
      },
      {
        name: "Storm's Wake Tabard",
        id: "160542",
      },
    ],
  },
  "2163": {
    //Tortollan Seekers
    Honored: [
      {
        name: "Cape of the Scroll Keepers",
        id: "160538",
      },
      {
        name: "Scroll of Combustible Critters",
        id: "163215",
      },
      {
        name: "Recipe: Bountiful Captain's Feast",
        id: "162288",
      },
      {
        name: "Recipe: Contract: Champions of Azeroth",
        id: "162373",
      },
      {
        name: "Recipe: Contract: Tortollan Seekers",
        id: "162371",
      },
    ],
    Revered: [
      {
        name: "Girdle of the Scroll-Sages",
        id: "161529",
      },
      {
        name: "Antiquity Handler's Gloves",
        id: "161514",
      },
      {
        name: "Sea-Treated Footwraps",
        id: "161494",
      },
      {
        name: "Vambraces of a Thousand Year Toil",
        id: "161544",
      },
      {
        name: "Cou'pa",
        id: "163513",
      },
      {
        name: "Pattern: Embroidered Deep Sea Bag",
        id: "163026",
      },
      {
        name: "Formula: Enchant Ring - Seal of Critical Strike",
        id: "162298",
      },
      {
        name: "Formula: Enchant Ring - Seal of Versatility",
        id: "162301",
      },
      {
        name: "Recipe: Bountiful Captain's Feast",
        id: "162289",
      },
      {
        name: "Recipe: Codex of the Quiet Mind",
        id: "162358",
      },
      {
        name: "Recipe: Darkmoon Card of War",
        id: "162377",
      },
      {
        name: "Recipe: Endless Tincture of Renewed Combat",
        id: "162136",
      },
      {
        name: "Recipe: Galley Banquet",
        id: "162287",
      },
      {
        name: "Recipe: Grilled Catfish",
        id: "162292",
      },
      {
        name: "Recipe: Inked Vessel of Robust Regeneration",
        id: "162355",
      },
      {
        name: "Recipe: Inscribed Vessel of Mysticism",
        id: "162352",
      },
      {
        name: "Recipe: Seasoned Loins",
        id: "162293",
      },
      {
        name: "Recipe: Siren's Alchemist Stone",
        id: "162137",
      },
      {
        name: "Recipe: Tome of the Quiet Mind",
        id: "162376",
      },
      {
        name: "Technique: Glyph of the Dolphin",
        id: "162023",
      },
    ],
    Exalted: [
      {
        name: "Legguards of Ai'twen's Resurgence",
        id: "161534",
      },
      {
        name: "Shellbuckle Girdle",
        id: "161549",
      },
      {
        name: "Supple Moccasins of Pilgrimage",
        id: "161519",
      },
      {
        name: "Wristwraps of Scrollbinding",
        id: "161503",
      },
      {
        name: "Tabard of the Tortollan Seekers",
        id: "160543",
      },
    ],
  },
  "2164": {
    //Champions of Azeroth
    Honored: [
      {
        name: "Drape of the Azerothian Champion",
        id: "160537",
      },
    ],
    Revered: [
      {
        name: "Drop of Azerite",
        id: "163555",
      },
      {
        name: "Shard of Azerite",
        id: "163515",
      },
    ],
    Exalted: [
      {
        name: "Azerothian Champion's Crown",
        id: "161555",
      },
      {
        name: "Azerothian Champion's Spaulders",
        id: "161560",
      },
      {
        name: "Helm of the Azerothian Champion",
        id: "161563",
      },
      {
        name: "Vest of the Azerothian Champion",
        id: "161557",
      },
      {
        name: "Champions of Azeroth Tabard",
        id: "160544",
      },
    ],
  },
};

export default rewards;
